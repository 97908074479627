import React from "react";
// Customizable Area Start
import "./style.css";
import {
    settingicon,
    subarrowicon,
    logoNew,
    SuccessCard
} from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import {
    Box,
    Typography,
    OutlinedInput,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { backIcon } from "../../forgot-password/src/assets";
import Grid from '@material-ui/core/Grid';
import InfiniteScroll from "react-infinite-scroll-component";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2CE2D5",
            contrastText: "#fff",
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#fff",
                },
                "& .MuiOutlinedInput-input": {
                    width: '100%',
                    color: "white",
                    borderBottom: '0px',
                },
                "& $notchedOutline": {
                    borderColor: "#94989c",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                },
            },
        },
    },
});
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
export default class Dashboard extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start

    renderDir =()=>{
        return this.state.direction === 'rtl' ? 'rtl' : 'ltr'
      }

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <div id="scrollableDiv" style={webStyle.scrollBarStyle} dir={this.renderDir()}>
                <div className="header-wrapper1" style={{ gap: '5px' }}>
                    <div className="page-title" style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px'
                    }}>
                        <NavLink to="/CryptoSelection"><img src={backIcon} alt="back-icon" style={{transform: this.state.direction ==='rtl'? 'scaleX(-1)':'' }} /></NavLink>
                        <h4 className="add-stock page-info">
                             {this.getStringDashboard("AddCryptoTicker")}
                        </h4>
                    </div>
                    <div className="add-stock page-info">
                        <div id="addStockFile" className="drop-menu dropDoenMenu" style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                            <div
                                id="stockAddListing"
                                data-test-id="currencyDropdoen1"
                                onClick={() => this.handleDropdown()}
                                className={this.state.dropdownVisiblity}
                            >
                                <p >

                                    {this.state.selectedCurrency} <img src={subarrowicon} alt="subarrow" />
                                </p>

                                <ul >
                                    {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                                        return <li data-test-id={"currency-" + index} onClick={() => this.selectCurrency(value)}>{value.symbol + value.currency_type}</li>
                                    })}
                                </ul>
                            </div>
                            <NavLink id="settingRedirectionFromAddStock" to="/Account-Detail">
                                <img src={settingicon} alt="setting" />
                            </NavLink>
                        </div>

                    </div>
                </div>
                <ThemeProvider theme={theme}>

                    <Box>
                        <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                            <Grid item xs={12} sm={6}>
                                <div style={{ marginBottom: '15px' }}>
                                    <Typography style={webStyle.formLabel1}>{this.getStringDashboard("EnterQuantity")}</Typography>
                                    <OutlinedInput
                                        onChange={(e) => this.tickerQuantityUpdate1(e.target.value)}
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px', }}
                                        data-test-id="quantityId"
                                        className="inputBox"
                                        placeholder="Quantity"
                                        fullWidth={true}
                                    />
                                </div>
                                <div>
                                    <Typography style={webStyle.formLabel1}>{this.getStringDashboard("ChooseCoin")}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px', }}
                                        className="inputBox"
                                        data-test-id="searchTicker"
                                        fullWidth={true}
                                        placeholder="Search coin"
                                        onChange={(e) => this.searchTicker1(e.target.value)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>

                            </Grid>

                        </Grid>


                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                            <InfiniteScroll
                            dataLength={this.state.cryptotickerList.length}
                            next={this.cryptotickerList}
                            hasMore={true}
                            loader={<p></p>}
                            scrollableTarget="scrollableDiv"
                        >
                            <Grid container spacing={0} >
                                {this.state.cryptotickerList && this.state.cryptotickerList.map((values: {
                                    ticker: string,
                                    icon_url: string,
                                    id: string,
                                    name: string
                                }) => (
                                    <Grid item xs={12} sm={4} md={4} lg={3} style={{ padding: '10px' }} key={values.id} >
                                        <Box
                                            style={this.state.ticker_id1 != values.id ? webStyle.stockCard1 : webStyle.stockCardActive1} data-test-id="crypto-ticker"
                                            onClick={() => { this.setTickerId(values.id) }}
                                        >
                                            <Box style={{ ...webStyle.stockCard3, flexDirection: 'column' }}>
                                                <Box >
                                                    <img src={values.icon_url ? values.icon_url : logoNew} style={{ width: '50px', height: "50px", margin: 'auto' }} />
                                                </Box>
                                                <Typography style={this.state.ticker_id1 != values.id ? webStyle.tickerLabels1 : webStyle.tickerLabelsActive1}>
                                                    {values.ticker}
                                                </Typography>
                                                <Typography style={this.state.ticker_id1 != values.id ? webStyle.tickerSubLabels1 : webStyle.tickerSubLabelsActive1}>
                                                    {values.name.length > 10 ? `${values.name.slice(0, 10)}...` : values.name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </InfiniteScroll>
                            </Grid>
                            <Grid item xs={12} sm={6}>

                            </Grid>
                        </Grid>
                    </Box>


                    <Box>
                        <Box style={{ ...webStyle.btnBox1, position: 'absolute', bottom: '20px', left: this.state.direction === "rtl"?'30px':'', right: this.state.direction === "rtl"?'': '30px',padding:'12px', borderRadius:'8px' }} data-test-id="tickerSubmit1" onClick={() => this.addCryptoTicker()}>
                            <Typography style={webStyle.btnLabel1}>
                                {this.getStringDashboard("Confirm")}
                            </Typography>
                        </Box>
                    </Box>
                    <Dialog
                        open={this.state.openSuccessModal}
                        keepMounted
                        data-test-id="dialogModel"
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogSuccessPaperStyle }}
                        onClose={() => this.goToBackPage()}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
                        <DialogContent style={{ padding: '8px 20px', textAlign: "center" }}>
                            <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{
                                textAlign: "center"
                            }}>
                                {this.getStringDashboard("PositionSuccess")}
                            </DialogContentText>

                        </DialogContent>
                    </Dialog>
                </ThemeProvider>
            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "red !important",
        }
    },
    tickerSubLabelsActive1: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '11px', fontWeight: 500 },
    btnLabel1: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 700 },
    tickerSubLabels1: { color: '#fff', fontFamily: 'Poppins', fontSize: '11px', fontWeight: 500 },
    btnBox1: { cursor: 'pointer', backgroundColor: '#C9F9F6', width: '135px', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' },
    tickerLabels1: { color: '#fff', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '10px' },
    stockCard3: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', borderRadius: '12px' },
    stockCard1: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', borderRadius: '12px', background: '#2A2A39', boxShadow: '0px 0px 10px 2px rgba(6, 141, 132, 0.45)' },
    stockTickerLabel: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '24px' },
    tickerLabelsActive1: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '10px' },
    stockCardActive1: { cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px', borderRadius: '12px', background: '#2CE2D5', boxShadow: '0px 0px 10px 2px rgba(6, 141, 132, 0.45)' },
    stockPortfolioLabel: { color: '#FFF', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '10px' },
    formLabel1: { color: '#FFF', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500 },

    inputRootForPayments: {
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },
        height: '56px',
        '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
            padding: '14.5px 14px',
        },
        fontFamily: 'Poppins',
        outline: 'none',
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        '& input::placeholder': {
            color: '#f44336 ',
            opacity: 0.5,
            fontSize: '14px',
            fontWeight: 500,
        },
        '& .MuiInputBase-input': {
            borderColor: 'white !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#94989c !important',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        borderRadius: '8px',
    },
    scrollBarStyle: { height: "100vh", overflow: "auto", width: "100%", background: "rgba(0, 0, 0, 0.3)", padding: '25px' }
}
// Customizable Area End
