import React from "react";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import { plus, pieChartVector, noOtherData } from "./assets";
import { subarrowicon, settingicon } from "../../dashboard/src/assets";
import "../../dashboard/src/style.css";
import "./style.css";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addCommas } from "../../../components/src/commonFn";

// Customizable Area End

import GoalmanagementController, {
    Props,
} from "./GoalmanagementController";

export default class VisualAnalytics extends GoalmanagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    addCssArabic = () => {
        return this.state.logoImg1AddCash === "ltr" ? "hide-element hide-element-arabic" : "hide-element hide-element-new"
    }
    monthlyBudget = () => {
        return this.state.logoImg1AddCash === "rtl" ? "monthlyBudget2New" : "monthlyBudget2"
    }
    dialogPaperStyleNewGoals = () => {
        return this.state.logoImg1AddCash === "rtl" ? this.dialogPaperStyleNew : this.dialogPaperStyle
    }
    textDataNewCss = () => {
        return this.state.logoImg1AddCash === "rtl" ? "textDataNew" : "textData"
    }
    modalButton = () => {
        return this.state.logoImg1AddCash === "rtl" ? "modal-button-container-New" : "modal-button-container"
    }
    getSpendingValue =(operand:string,goal_amount:string,currency:string,remain_balance:string ) =>{
        return operand ==="+"? goal_amount: operand + currency +  remain_balance

    }
    getProgressbar = (remain_balance_operand: string, summary_percentage: string, overspent_percentage: string) => {
        return (
            <div className="progress">

                {remain_balance_operand === "+" ?
                    <div className="progress-done" style={{
                        width: `${summary_percentage}%`,
                    }}>
                    </div> :
                    <div style={{ display: 'flex', justifyContent: 'flex-end', height: '100%', backgroundColor: '#2de2d5', borderRadius: '20px' }}>
                        <div className="progress-done" style={{
                            width: overspent_percentage + "%", backgroundColor: '#EE5353'
                        }}>
                        </div>

                    </div>}
            </div>
        )

    }
    getProgressbarColor =(remain_balance_operand:string)=>{
        return remain_balance_operand === "+" ? '#7d62b1' : '#EE5353'
    }
    getCommaAmount = (amount: string | number | null | undefined) => {
        return addCommas(JSON.stringify(amount))
    }
    getBackgroundStrock =(operand: string)=>{
        return operand ==="-"?"#8e34f5": "";
    }
    getMonthlyBCStrock =(operand: string)=>{
        return operand === "-" ? "#EE5353" :"";
    }
    remainingAmountClass =(operand: string)=>{
        return operand === "-" ? "graph-groth negative-red":"graph-groth"
    }
    getServiceBoxClass =(idBoolean: boolean, isBox:boolean )=>{
        if(isBox){
            return idBoolean ? "custome-myne-service-box opacity-change" : "custome-myne-service-box";
        }else{
            return idBoolean ? "option-svg option-delete pointer-show" : "option-svg pointer-show"
        }

    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <div className="pagedashboard-wrapper" data-test-id="currencyConversionGoals" dir={this.state.logoImg1AddCash}>
                    <div className="header-wrapper" id="wrapperGoalCurrencyConversion" onClick={this.handleGlobaldropdown}>
                        <div className="page-title currencyConversionGoalPageTitle">
                        </div>
                        <div className="page-info currencyConversionGoalPageinfo">
                            <ul className="drop-menu" data-test-id="dropdownCurrencyGoal">
                                <li className="DropDownOfGoal">
                                    <div
                                        id="GoalManagement"
                                        data-test-id="currencyDropdoen"
                                        onClick={() => this.handleDropdown()}
                                        className={this.state.dropdownVisiblity} >
                                        <p data-test-id="fake-datatest-id" dir="ltr">
                                            {this.state.selectedCurrency}
                                        </p>
                                        <img src={subarrowicon} alt="subarrow" />
                                        <ul id="fake-id">
                                            {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                                                return <li style={{textAlign: this.state.logoImg1AddCash ==='rtl'? 'right': 'left'}} data-test-id={`currency-${index}`} onClick={() => this.selectCurrencyGoal(value)}>{value.symbol + value.currency_type}</li>
                                            })}

                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <NavLink className="fakeClass" to="/Account-Detail">
                                        <img src={settingicon} alt="setting" id="setting-img" />
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="content-wrapper content-wrapper-goal">
                        {this.state.dashboardInitialStage ?
                            <>
                                <div className="screen-wrapper-goal screen-wrapper">
                                    <div className="budget-btns">
                                        <div className="monthlyBudget" data-test-id="monthly-budget" onClick={() => { this.redirectToMonthlyGoal() }}>
                                            <span className="budgetPlusIcon"><img src={plus} /></span>
                                            <span className="budgetBtnsText">{this.getStringGoalmanagement("CreateGoal")}</span>
                                        </div>
                                        <div className="monthlyBudget" data-test-id="redirectToOtherGoals" onClick={() => { this.redirectToOtherGoals() }}>
                                            <span className="budgetPlusIcon"><img src={plus} /></span>
                                            <span className="budgetBtnsText">{this.getStringGoalmanagement("CreateSpending")}</span>
                                        </div>


                                    </div>
                                    <div className="no-data-section">
                                        <div className="noDataImg"><img src={pieChartVector} /></div>
                                    </div><div className="no-data-section1">
                                        <div className="noDataMsg">{this.getStringGoalmanagement("Goals")}</div>
                                    </div>
                                </div>

                            </>
                            :
                            <>
                                <div className="screen-wrapper-goal screen-wrapper">
                                    <div className="budget-btns2">

                                        <div className={this.monthlyBudget()} data-test-id="monthly-budget" onClick={() => { this.redirectToMonthlyGoal() }}>
                                            <span className="budgetPlusIcon"><img src={plus} /></span>
                                            <span className="budgetBtnsText">{this.getStringGoalmanagement("CreateGoal")}</span>

                                        </div>
                                        {this.state.isMonthlyGoalAvailable ?
                                            <>
                                                <div className="radial-quarters-wrapper">
                                                    <div className="card-process">
                                                        <div className="percent">
                                                            <svg>
                                                                <circle cx="105" cy="105" r="100" style={{stroke: this.getBackgroundStrock(this.state.monthlyGoal.operand)}} ></circle>
                                                                <circle cx="105" cy="105" r="100" style={{strokeDashoffset: `calc(625px - (625px * ${this.state.goalRemainingAndDone}) / 100)`, stroke: this.getMonthlyBCStrock(this.state.monthlyGoal.operand) }}></circle>
                                                                
                                                            </svg>
                                                            <div className="process-data-number">
                                                                <h3><span style={{whiteSpace:'nowrap'}}>{this.state.monthlyGoal.operand + this.state.monthlyGoal.currency + this.getCommaAmount(this.state.monthlyGoal.remaining_spending)}</span><span>Left of { this.state.monthlyGoal.currency + this.getCommaAmount(this.state.monthlyGoal.goal_amount)}</span></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="quartters-details">
                                                        <div className="graph-text">
                                                            <span className="graph-month">
                                                                <span className="left-btn pointer-show" data-test-id="updateCurrentMinus" onClick={() => this.updateCurrentMonth('minus')}>&lt;</span>
                                                                <span className="month-name">&nbsp;{this.state.transactionMonth[this.state.selectedTransactionCurrentMonth]}&nbsp;</span>
                                                                <span className="right-btn pointer-show" data-test-id="updateCurrentPlus" onClick={() => this.updateCurrentMonth('plus')}>&gt;</span></span>
                                                        </div>
                                                        <div className="all-quartters">
                                                            <div className="quartters-months">
                                                                <div className="graph-legend">
                                                                    <span className="graph-legend-icon purple">
                                                                    </span>&nbsp;{this.getStringGoalmanagement("Spending")}
                                                                </div>
                                                                <div className="graph-groth">{this.state.monthlyGoal.currency + this.getCommaAmount(this.state.monthlyGoal.spending_balance)}</div>
                                                            </div>
                                                            <div className="quartters-months">
                                                                <div className="graph-legend">
                                                                    <span className="graph-legend-icon">
                                                                    </span>&nbsp;{this.getStringGoalmanagement("Remaining")}
                                                                </div>
                                                                <div className={this.remainingAmountClass(this.state.monthlyGoal.operand)}>{this.state.monthlyGoal.operand + this.state.monthlyGoal.currency + this.getCommaAmount(this.state.monthlyGoal.remaining_spending)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> :
                                            <>
                                                <div className="no-data-section2">
                                                    <div className="noDataImg1"><img src={pieChartVector} /></div>
                                                </div><div className="no-data-section3">
                                                    <div className="noDataMsg">{this.getStringGoalmanagement("Goals")}</div>
                                                </div>
                                            </>
                                        }
                                        <div data-test-id="redirectToOtherGoalsNew" className={this.monthlyBudget()} onClick={() => { this.redirectToOtherGoals() }}>
                                            <span className="budgetPlusIcon"><img src={plus} /></span>
                                            <span className="budgetBtnsText">{this.getStringGoalmanagement("CreateSpending")}</span>
                                        </div>
                                        {this.state.isOtherGoalAvailable ?
                                            <>
                                                <div className="myne-service-wrapper">
                                                    <div className="myne-service-title">
                                                        <h4>{this.getStringGoalmanagement("BudgetGoals")}</h4>
                                                    </div>
                                                    <div className="custome-myne-service">
                                                        {this.state.otherGoals && this.state.otherGoals.map((value: any, index: number) => {
                                                            return (
                                                                <>
                                                                    <div style={{cursor:'pointer'}} className={this.getServiceBoxClass(this.state.deletePopupId == value[1].id, true)} onClick={()=>this.gotoTransactions(value[1])} data-test-id="specificGoal">
                                                                        <div className="custome-myne-name">
                                                                            <h5>{this.truncateName(value[0])}</h5>
                                                                            <div className={this.getServiceBoxClass(this.state.deletePopupId == value[1].id,false)} data-test-id={`delete-goal-new-${index}`} onClick={(event) => this.updateDeletePopupId(event,value[1].id)}>
                                                                                <svg className="menu-dot-icon" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 128 512"><path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" /></svg>
                                                                                <div className={this.addCssArabic()} data-test-id="popup-save-click-new" onClick={(event) => this.onCancel(event)}>{this.getStringGoalmanagement("Delete")}</div>
                                                                            </div>
                                                                            <Dialog
                                                                                open={this.state.openModel}
                                                                                keepMounted
                                                                                data-test-id="dialogModel"
                                                                                onClose={this.dialogModel}
                                                                                aria-labelledby="alert-dialog-slide-title"
                                                                                aria-describedby="alert-dialog-slide-description"
                                                                                PaperProps={{ style: this.dialogPaperStyleNewGoals() }}
                                                                                style={{ textAlign: 'center' }}
                                                                            >
                                                                                <DialogTitle id="alert-dialog-slide-title">
                                                                                    <span className={this.textDataNewCss()}>{this.getStringGoalmanagement("deleteGoal")}</span></DialogTitle>
                                                                                <DialogContent style={{
                                                                                    padding: '0px!important',
                                                                                    marginBottom: '0px',
                                                                                    paddingTop: '15px'
                                                                                }}>
                                                                                    <DialogContentText id="alert-dialog-slide-description"
                                                                                        style={{
                                                                                            color: 'rgb(255, 255, 255) !important',
                                                                                            fontSize: '20px !important',
                                                                                            fontFamily: 'Poppins !important',
                                                                                            fontWeight: 500,
                                                                                            marginBottom: '0px',
                                                                                        }}>
                                                                                        <div className={this.modalButton()}>
                                                                                            <div className="modal-button modal-save" data-test-id='popup-save-click' onClick={(event) => this.onCancel(event)}>
                                                                                                {this.getStringGoalmanagement("Cancel")}
                                                                                            </div>
                                                                                            <div data-test-id={`delete-goal-${index}`} onClick={(event) => this.deleteOtherGoal( event,this.state.deleteSpendingGoalId)} className="modal-button modal-discard">
                                                                                                {this.getStringGoalmanagement("Delete")}
                                                                                            </div>
                                                                                        </div>
                                                                                    </DialogContentText>
                                                                                </DialogContent>
                                                                            </Dialog>
                                                                        </div>
                                                                        <div className="budget-icon">
                                                                            <img src={value[1].sub_category?.image} alt="bag" />
                                                                        </div>
                                                                        <div className="package-with-price">
                                                                            <div className="package-name">
                                                                                <h4>{value[1].sub_category?.sub_category}</h4>
                                                                            </div>
                                                                            <div className="price">
                                                                                <span dir="ltr" data-test-id="goalPriceBtnId" style={{cursor:'pointer', color: this.getProgressbarColor(value[1].remain_balance_operand)}} onClick={()=>this.gotoTransactions(value[1])} > 
                                                                                {this.getSpendingValue(value[1].remain_balance_operand, value[1].goal_amount, value[1].currency, value[1].remain_balance )} 
                                                                                 </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="achieved">
                                                                            <span>{value[1].summary}</span>
                                                                        </div>
                                                                        {this.getProgressbar(value[1].remain_balance_operand,value[1].summary_percentage,value[1].overspent_percentage)}
                                                                        <div className="package-price-with-days">
                                                                            <span dir="ltr" className="package-price">{value[1].spending_result}</span>
                                                                            <span className="package-days">{value[1].days_left} </span>
                                                                        </div>
                                                                    </div></>
                                                            )



                                                        })
                                                        }

                                                    </div>
                                                </div>
                                            </> :
                                            <>
                                                <div className="no-data-section2">
                                                    <div className="noDataImg1"><img src={noOtherData} /></div>
                                                </div><div className="no-data-section3">
                                                    <div className="noDataMsg">{this.getStringGoalmanagement("Goals")}</div>
                                                </div>
                                            </>
                                        }


                                    </div>
                                </div>
                            </>
                        }
                    </div>

                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
