import React from "react";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import { backIcon } from "../../forgot-password/src/assets";
import { subarrowicon, settingicon, calendarImg } from "../../dashboard/src/assets";
import { modelSuccess } from "../../multipageforms2/src/assets";
import "../../dashboard/src/style.css";
import "./style.css";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {image_BackIcon} from "./assets";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Select from 'react-select';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ErrorMessage, Form, Formik } from "formik";
import { Box, OutlinedInput } from "@material-ui/core";

let theme = createTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#2CE2D5",
        },
    },
});

theme = createTheme(theme, {
    overrides: {
        MuiSvgIcon: {
            root: {
                color: '#94989c',
            },
        },
        MuiOutlinedInput: {
            root: {
                "& .MuiOutlinedInput-input": {
                    color: "white",
                    borderBottom: '0px',
                },
                "& $notchedOutline": {
                    borderColor: "#94989c",
                },
                "& .MuiAutocomplete-endAdornment": {
                    position: 'unset'
                },
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#fff",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                },
          
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#3C3C51",
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)"

            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)",
                "& button": {
                    backgroundColor: "rgb(57, 93, 107)",
                    color: "rgb(181, 176, 176)",
                }
            },
            dayheader: {
                backgroundColor: "rgb(57, 93, 107)",
                color: "rgb(181, 176, 176)"
            },
            dayLabel: {
                color: "rgb(181, 176, 176)"
            }
        },
        MuiPickersDay: {
            day: {
                color: "rgb(181, 176, 176)",
            },
            daySelected: {
                backgroundColor: "#2CE2D5",
            },
            dayDisabled: {
                color: "#ccc",
            },
            current: {
                color: "rgb(181, 176, 176)"
            },
            dayLabel: {
                color: "rgb(181, 176, 176)"
            }
        },
        MuiPickersModal: {
            dialogAction: {
                color: "#33abb6",
                backgroundColor: "#3C3C51",
            },
        },
    },
});
import MultipleSelectInputFiled from "../../../components/src/SeleteBox/MultipleSelectInputFiled";
// Customizable Area End

import GoalmanagementController, {
    Props,
} from "./GoalmanagementController";

export default class VisualAnalytics extends GoalmanagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    arabiccss = () => {
        return this.state.logoImg1AddCash === "rtl" ? "saveMonthlyBudget saveMonthlyBudgetNew" : "saveMonthlyBudget"
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
            <div className="pagedashboard-wrapper" dir={this.state.logoImg1AddCash}>
                <div className="header-wrapper">
                    <div className="page-title">
                        <h4><NavLink to="/Goalmanagement"><img src={this.state.logoImg1AddCash === "rtl" ? image_BackIcon : backIcon} alt="back-icon" /></NavLink> &nbsp;{this.getStringGoalmanagement("CreateBudget")}</h4>
                    </div>
                    <div className="addMonthlyGoal page-info">
                        <ul data-test-id="addMonthlyGoal" className="drop-menu">
                            <li className="monthlyGoalAdd">
                                <div
                                    data-test-id="currencyDropdoen"
                                    onClick={() => this.handleDropdown()}
                                    className={this.state.dropdownVisiblity} >
                                    <p data-test-id="fakeDataTestIdAddMonthlyGoal" dir="ltr">
                                        {this.state.selectedCurrency} 
                                    </p>
                                    <img src={subarrowicon} alt="subarrow" />
                                    <ul id="fakeIdForMonthlyGoalPage">
                                        {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                                            return <li style={{textAlign: this.state.logoImg1AddCash ==='rtl'? 'right': 'left'}}  data-test-id={`currency-${index}`} onClick={() => this.selectCurrencyGoal(value)}>{value.symbol + value.currency_type}</li>
                                        })}

                                    </ul>
                                </div>
                            </li>
                            <li>
                                <NavLink className="fakeClassForNavLink" to="/Account-Detail">
                                    <img src={settingicon} alt="setting" id="setting-img" />
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                    <div className="content-wrapper addMonthlyPageWrapper">
                        <Formik
                            enableReinitialize
                            initialValues={this.state.monthlyGoalData}
                            onSubmit={(values) => {
                                this.setMonthlyGoal(values)
                            }}
                            data-test-id="addMonthlyGoalForm"
                            validationSchema={this.validationSchemaGoal}
                        >
                            {({ handleBlur, values, handleSubmit, handleChange, setFieldValue }) => (
                                <Form noValidate onSubmit={handleSubmit} data-test-id='submitForm' translate={"en"}>
                                    <div className="screen-wrapper-demo">
                                        <div className="fieldDiv inputFieldStyle">
                                            <p className="labelText">{this.getStringGoalmanagement("StartDate")}</p>
                                            <Box data-test-id="datePicker">
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        format="dd/MM/yyyy"
                                                        name="startDate"
                                                        value={values.start_date}
                                                        error={false}
                                                        placeholder="DD/MM/YYYY"
                                                        variant="inline"
                                                        fullWidth
                                                        inputVariant="outlined"
                                                        onChange={(values) => setFieldValue("start_date", values, true)}
                                                        autoOk
                                                        InputProps={{
                                                            style: {
                                                                borderRadius: '8px',
                                                                color: 'white',
                                                                padding: '10px 0px',
                                                            }
                                                        }}
                                                        data-test-id="startDate"
                                                        helperText=""
                                                        className="uploadBox newBoxContant12Pension"
                                                        KeyboardButtonProps={{
                                                            style: {
                                                                color: 'white'
                                                            }
                                                        }}
                                                        keyboardIcon={<img src={calendarImg} style={{
                                                            borderRadius: "0",
                                                            marginTop: "-4px",
                                                            height: "18px",
                                                            width: "18px",
                                                        }} />}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Box>
                                            <Box sx={webStyle.fieldError}>
                                                <ErrorMessage name="start_date" />
                                            </Box>
                                        </div>
                                        <div className="fieldDiv fieldDiv2">
                                            <div className="fieldInnerDiv inputFieldStyle">
                                                <p className="labelText">{this.getStringGoalmanagement("Amount")}</p>
                                                <OutlinedInput
                                                    style={webStyle.inputRoot}
                                                    data-test-id="amount"
                                                    value={values.amount}
                                                    fullWidth={true}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    placeholder={"Enter amount"}
                                                    name="amount"
                                                    type="number"
                                                />
                                                <Box sx={webStyle.fieldError}>
                                                    <ErrorMessage name="amount" />
                                                </Box>

                                            </div>
                                            <div className="fieldInnerDiv myne-goals inputFieldStyle">
                                                <p className="labelText">{this.getStringGoalmanagement("Currency")}</p>
                                                <Select
                                                    styles={{
                                                        control: (defaultStyles) => ({
                                                            ...defaultStyles,
                                                            padding: "8px",
                                                            backgroundColor: "#2a2a38",
                                                            borderRadius: '8px',
                                                            color: 'rgba(255, 255, 255, 0.60)',
                                                            fontSize: '16px',
                                                            fontFamily: 'Poppins',
                                                            textAlign: "left",
                                                        }),
                                                    }}
                                                    classNamePrefix="an-simple-select"
                                                    className="selectMenu selectedMenuDropdown"
                                                    data-test-id="selectCurrencyMenu"
                                                    value={values.currency}
                                                    placeholder="Select Currency"
                                                    options={this.state.currencyListDropDownForm}
                                                    onChange={(event) => event && setFieldValue("currency", event)}
                                                />
                                                <Box sx={webStyle.fieldError}>
                                                    <ErrorMessage name="currency" />
                                                </Box>
                                            </div>
                                        </div>
                                        <div className="fieldDiv inputFieldStyle">
                                            <p className="labelText">{this.getStringGoalmanagement("selectYourBanks")}</p>
                                            <MultipleSelectInputFiled
                                                selectedBanks={values.selectedBanks}
                                                banks={this.state.bankOptions}
                                                setFieldValue={setFieldValue}
                                            />
                                            <Box sx={webStyle.fieldError}>
                                                <ErrorMessage name="selectedBanks" />
                                            </Box>
                                        </div>
                                    </div>
                                    <div className={this.arabiccss()}>
                                        <span onClick={() => handleSubmit()} className="save-btn-filter-other-goal right-align" data-test-id="formSubmit">{this.getStringGoalmanagement("MonthlyBtn")}</span>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                <Dialog
                    open={this.state.openSuccessModel}
                    keepMounted
                    id="monthlyGoalDialogId"
                    data-test-id="dialogModel"
                    onClose={this.dialogCashModel}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    PaperProps={{ style: this.dialogCashPaperStyle }}
                >
                    <DialogTitle data-test-id="monthlyGoalDialogTitle" id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={modelSuccess} /></DialogTitle>
                    <DialogContent style={{ padding: '8px 20px' }} data-test-id="DialogContentForMonthlyGoal">
                        <DialogContentText id="alert-dialog-slide-description" className='dialogContent' data-test-id="contentTextForMonthlyGoal">
                            {this.getStringGoalmanagement("Successfully")}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

type IWStyle = { [key: string]: React.CSSProperties }

const webStyle: IWStyle = {
    inputRoot: {
        height: '56px',
        outline: 'none',
        fontFamily: 'Poppins',
        borderRadius: '8px',
    },
    fieldError:{
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        fontStyle: 'normal'
   },
   fieldStyle:{
    marginTop:"15px"
   },
   nextButton:{
        color: '#3C3C50',
        fontSize: '17px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        lineHeight: '24px',
        textTransform: 'none',
        textAlign: 'center',
        width: "auto",
   },
   
}
// Customizable Area End
