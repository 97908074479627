import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { FieldArray, Formik } from "formik";
import "../../multipageforms2/assets/PortableAsset.css";
import "./style.css";
import UploadThingController, {
    Props
} from "./uploadThingController.web";
import { styled } from '@material-ui/styles';
import Select from 'react-select';
import UserProfile from "../../../components/src/UserProfile.web";
import { imagenav_BackIcon, AddCircle, Trash, modelSuccess } from "./assets";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Customizable Area End

// Customizable Area Start
const cashTheme = createTheme({
    overrides: {
        MuiOutlinedInput: {
            root: {
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#fff",
                },
                "& .MuiOutlinedInput-input": {
                    borderBottom: '0px',
                    color: "white",
                    width: '100%',
                },
                "& $notchedOutline": {
                    borderColor: "#94989c",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                },
            },
        },
        MuiDialog: {
            paper: {
                backgroundColor: '#395D6B',
                boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
                width: 'max-content',
                borderRadius: '8px',
            },
        },
    },
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#2CE2D5",
        },
    },
});
// Customizable Area End

export default class UploadThing extends UploadThingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            // Customizable Area Start
            <>
                <style>
                    {`
                        .an-simple-select__control {
                            background-color: rgb(60, 60, 81) !important;
                        }
                        .cash_title_service {
                            padding-top: 0px !important
                        }
                    `}
                </style>
                <Box style={{ height: '100vh', width: '90%' }}>

                    <Box className='cash_mainWrappers_Box' style={{ minHeight: "100%", width:'auto' }}>
                        <Box className="cash_sideBorder" dir={this.state.logoImg1AddCash}>
                            <ThemeProvider theme={cashTheme}>
                                <CssBaseline />
                                <Grid container style={{
                                    flexWrap: "wrap",
                                    background: "#2a2a39"
                                }} >
                                    <Grid item xs={12} style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        justifyContent: "space-between"
                                    }}>
                                        <Box style={webStyle.profileNameNew}>
                                            <Box onClick={() => this.goToBackPage()} data-test-id="backButton" ><img src={imagenav_BackIcon} style={webStyle.marginImg1} alt="back" /></Box>
                                            <Box style={webStyle.planning}> Clients</Box>
                                        </Box>
                                        <UserProfile username={this.state.plannerInfo.name} email={this.state.plannerInfo.email}  profilePicture={this.state.plannerInfo.image} />
                                    </Grid>
                                    <Grid item md={12} style={{
                                        marginBottom: "24px"
                                    }} className="tabsContainer-service">
                                        <Grid container className="clientTabsSpacing" >
                                            <Grid item >
                                                <Typography style={webStyle.inActiveTab} onClick={() => this.handleTabDetails()} data-test-id="Details-tab"> Details </Typography>
                                            </Grid>
                                            <Grid item >
                                                <Typography style={webStyle.inActiveTab} onClick={() => this.handleTabAssets()} data-test-id="Assets-tab"> Assets </Typography>
                                            </Grid>
                                            <Grid item >
                                                <Typography style={webStyle.inActiveTab} onClick={() => this.handleTabService()} data-test-id="service-tab"> Services </Typography>
                                            </Grid>
                                            <Grid item >
                                                <Typography style={webStyle.activeTab} onClick={() => this.handleTabVault()} data-test-id="Vault-tab"> Vault </Typography>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Formik
                                    initialValues={{
                                        selectService: this.state.selectService.label,
                                        serviceNodes: [
                                            {
                                                index: 0,
                                                serviceId: "",
                                                categoryId: "",
                                                service: {},
                                                subCategory: {},
                                                uploadDocumentImage: []
                                            }
                                        ]
                                    }}
                                    onSubmit={async (values: any, { resetForm }) => {
                                        await this.handleSubmit(values)
                                        resetForm();
                                    }}
                                    data-test-id="upload-documents"
                                    validateOnChange={false}
                                    validateOnBlur={false}

                                >

                                    {({ values, handleSubmit, setFieldValue }: any) => {
                                        return (
                                            <form onSubmit={handleSubmit} data-test-id='submit-documnets'>
                                                <Box>
                                                    <Typography>
                                                        <Box>
                                                            <FieldArray
                                                                data-test-id="field-array"
                                                                name="serviceNodes"
                                                                render={(arrayHelpers) => (
                                                                    <>
                                                                        {

                                                                            values.serviceNodes.map((serviceItem: any, idx: number) => (
                                                                                <Box className="cash_assetsCurrency addNewAddCashMainCurrency" style={{width:'100%', maxWidth:'900px'}}>
                                                                                    <Box className="upload-thing-wrapper">
                                                                                        <Typography style={{padding: '0rem !important'}} className="cash_title cash_title_service">Select a service</Typography>
                                                                                        <Box style={{ width: '100%' }} className="cash_uploadBox" data-test-id="handleService3">
                                                                                            <Select
                                                                                                value={values.selectService.label}
                                                                                                options={this.state.serviceListOptions}
                                                                                                onChange={(value: any) => {
                                                                                                    setFieldValue(`serviceNodes[${idx}].service`, value)
                                                                                                    setFieldValue(`serviceNodes[${idx}].serviceId`, value.value)
                                                                                                    this.handelSetService(value.value)
                                                                                                }}
                                                                                                name={'selectService3'}
                                                                                                classNamePrefix="an-simple-select"
                                                                                                className="selectMenu"
                                                                                                data-test-id="selectMenu3"
                                                                                                styles={this.cashCustomStyles} />
                                                                                        </Box>
                                                                                        <Typography className="cash_title">Select a sub-category</Typography>
                                                                                        <Box style={{ width: '100%' }} className="cash_uploadBox" data-test-id="handleService4">
                                                                                            <Select
                                                                                                value={values.selectService.label}
                                                                                                options={this.state.subCategoryListOptions[idx]}
                                                                                                onChange={(val: any) => {

                                                                                                    setFieldValue(`serviceNodes[${idx}].subCategory`, val)
                                                                                                    setFieldValue(`serviceNodes[${idx}].categoryId`, val.value)
                                                                                                }}
                                                                                                name={'selectService4'}
                                                                                                classNamePrefix="an-simple-select"
                                                                                                className="selectMenu"
                                                                                                data-test-id="selectMenu4"
                                                                                                styles={this.cashCustomStyles} />
                                                                                        </Box>
                                                                                        <Box
    
   onDragOver={(event: any) => {
        event.preventDefault();
    }}
     onDrop={(event: any) => {
        event.preventDefault(); 
            if (!event.dataTransfer || !event.dataTransfer.files) {
              return; 
            }
                 const files = [...serviceItem.uploadDocumentImage, ...Array.from(event.dataTransfer.files)];
            setFieldValue(`serviceNodes[${idx}].uploadDocumentImage`, files);
          
    }}
    
    style={{ ...webStyle.mMiddle, flexDirection: 'column' }}
    data-test-id="drag-n-drop-box"
>
    <Typography className="cash_title" style={{textAlign:'center'}}>Drag and drop document</Typography>
    <Typography className="cash_title">or</Typography>
    <label htmlFor={`uploadFile${idx}`}>
        <input
            data-test-id="upload-field"
            onChange={(event: any) => {
                const files = [...serviceItem.uploadDocumentImage, ...event.target.files];
                setFieldValue(`serviceNodes[${idx}].uploadDocumentImage`, Array.from(files));
            }}
            style={{ display: 'none' }}
            id={`uploadFile${idx}`}
            name={`uploadFile${idx}`}
            type="file"
            multiple
        />
        <Button
            data-test-id="menu-upload-button"
            component="span"
            style={{
                color: '#3C3C50',
                textAlign: 'center',
                fontSize: '21px',
                fontFamily: 'Poppins',
                backgroundColor: '#C9F9F6',
                textTransform: 'none',
                borderRadius: '7px',
                marginTop: '1rem'
            }}
            className="upload-document-button"
        >
            <b>Upload Document</b>
        </Button>
    </label>
    <Typography style={{ fontSize: '1rem', color: 'white', marginTop: '1rem' }}>
        Max file size 10 mb
    </Typography>
</Box>

                                                                                        {values.serviceNodes[idx].uploadDocumentImage && values.serviceNodes[idx].uploadDocumentImage.map((file: any, id: number) => (
                                                                                            <Box style={webStyle.deleteParent}>

                                                                                                <Box style={webStyle.mTop}>
                                                                                                    {file.type.startsWith('image/') && (
                                                                                                        <img src={URL.createObjectURL(file)} alt={`Preview of ${file.name}`} height="50px" width="50px" />
                                                                                                    )}
                                                                                                    <Typography style={{ marginLeft: '1.5rem' }} variant="h6" className="cash_title into-width">{file.name}</Typography>
                                                                                                </Box>
                                                                                                <img data-test-id="delete-image" onClick={() => setFieldValue(`serviceNodes[${idx}].uploadDocumentImage`, values.serviceNodes[idx].uploadDocumentImage.filter((mainFile: number, subId: number) => id !== subId))} src={Trash} style={{marginTop: '25px', width: '35px', height: '35px', cursor: "pointer" }} />
                                                                                            </Box>
                                                                                        ))
                                                                                        }


                                                                                    </Box>
                                                                                </Box>
                                                                            ))


                                                                        }

                                                                        <Box
                                                                            onClick={() => arrayHelpers.push({
                                                                                index: values.serviceNodes.length,
                                                                                serviceId: "",
                                                                                categoryId: "",
                                                                                service: {},
                                                                                subCategory: {},
                                                                                uploadDocumentImage: []
                                                                            })}
                                                                            style={webStyle.mTop}
                                                                            data-test-id="add_service"
                                                                        >
                                                                            <img src={AddCircle} style={{ width: '41px', height: '41px', cursor: "pointer" }} />
                                                                            <Typography style={{ marginLeft: '0.5rem', color: '#FFF', fontSize: '24px', fontFamily: "Poppins", fontWeight: 500 }} variant="h6"

                                                                            >Add Service</Typography>
                                                                        </Box>
                                                                    </>
                                                                )
                                                                }
                                                            />

                                                        </Box>
                                                    </Typography>
                                                    <Box className="upload-save-cancel-button-container" data-test-id="addCashWebFinalButton">
                                                        <Buttonss
                                                            data-test-id="submitButton"
                                                            variant="contained"
                                                            type={"button"}
                                                            style={{ color: '#C9F9F6', background: 'transparent', border: '1px solid #C9F9F6' }} onClick={() => this.handleTabDetails()} 
                                                        >
                                                            Cancel
                                                        </Buttonss>
                                                        <Buttonss
                                                            data-test-id="submitButton"
                                                            variant="contained"
                                                            type={"submit"}
                                                            style={{ color: 'rgba(0, 0, 0, 0.87)', background: '#C9F9F6' }}

                                                        >
                                                            Save
                                                        </Buttonss>
                                                    </Box>
                                                </Box>
                                            </form>
                                        )
                                    }

                                    }
                                </Formik>
                            </ThemeProvider>
                        </Box>
                    </Box>
                    <Box>
                        <Dialog
                            open={this.state.openSuccessModal}
                            keepMounted
                            data-test-id="dialogModel"
                            onClose={this.dialogSuccessModal}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                            PaperProps={{ style: this.dialogCashPaperStyle }}
                        >
                            <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={modelSuccess} /></DialogTitle>
                            <DialogContent style={{ padding: '8px 20px' }}>
                                <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-slide-description" className='dialogContent'>
                                    Your documents are successfully saved in the vault!
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </Box>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "red !important",
        }
    },
    buttons: {
        backgroundColor: "#c7f9f6",
        borderRadius: '10px',
        margin: '0px',
        Color: '#3C3C50',
        height: "50px",
        fontWeight: 700,
        fontSize: '20px',
        textTransform: 'none',
    },
    inputStyle: {
        border: "1px solid white",
        padding: "15px",
    },
    mTop: {
        display: 'flex',
        marginTop: '1rem',
        alignItems: 'center',
        cursor: 'pointer'
    },
    mMiddle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '40%',
        border: '2px grey dashed',
        marginTop: '2rem'
    },
    deleteParent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    profileNameNew: { display: 'flex', alignItems: 'center' },
    marginImg1: { margin: '0px', cursor: "pointer" },
    planning: { fontSize: "22px", color: "fff", fontFamily: 'Poppins', fontWeight: 500, marginLeft: "18px" },
    inActiveTab: { fontSize: "22px", color: "2de2d5", cursor: "pointer", fontFamily: "Poppins" },
    activeTab: { fontSize: "22px", fontWeight: 600, color: "2de2d5", cursor: "pointer", borderBottom: "1px solid", fontFamily: "Poppins" },
    inputRoot: {
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },
        height: '56px',
        '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
            padding: '14.5px 14px',
        },
        fontFamily: 'Poppins',
        outline: 'none',
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        '& input::placeholder': {
            fontWeight: 500,
            fontSize: '14px',
            color: '#f44336 ',
            opacity: 0.5,
        },
        '& .MuiInputBase-input': {
            borderColor: 'white !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#94989c !important',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
        },
        borderRadius: '8px',
    },
};

const Buttonss = styled(Button)({
    textAlign: 'center',
    lineHeight: '24px',
    fontWeight: 700,
    fontFamily: 'Poppins',
    textTransform: 'none',
    fontSize: '17px',
    width: "auto",
    cursor: 'pointer',
    padding: '8px 45px 8px 45px',
    borderRadius: '8px',
})
// Customizable Area End
