import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, logoimg, imgPasswordVisible } from "./assets";
import toast from "react-hot-toast";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  logoImg1Login: string,
  logoimg: string;
  languagesLogin: string,
  paymentMethod: string,
  countries: any,
  countryPage: any,
  apiCallingStage: boolean;
  allCountriesLocation: any;
  countryName: any;
  isButtonClicked: boolean;
  deatailsData: {
        id: string,
        type: string,
        attributes: {
            name: string,
            currency: string,
            duration: string | null,
            amount: number,
            frequency: string
        }
  }[]
  freetrialid: any;
  changeplanid: any;
  monthlyid: any;
  yearlyid: any;
  token: any;
  address: any;
  plan_id: any;
  paymentMethods: {
        id: string,
        type: string,
        attributes: {
            card_name: string,
            primary_card: boolean,
            expires_on: string,
            card_number: string,
            card: string
        }
  }[];
  selectedPaymentMethod: any;
  paymentMethodAddDetails: { cardName: string, expiry: any, cardNumber: any, cvv: any },
  saveCardForFuture: boolean;
  addAddressFormDetails: { name: any, address: any, city: any, zip_code: any, country: any },
  openSuccessAddress: boolean,
  openDeleteSuccess: boolean,
  selectedPlan1: any;
  card_id: any;
  openRejectedPopup: boolean;
  openFreeTrialSuccessPopup: boolean
  selectedPlan: any;
  manageBtn: boolean;
  deleteErrorMsg: boolean;
  subscriptionDetails: {
    message: string,
    prompt: string,
    card:{},
    plan:{
      frequency: string,
      amount: number
    },
    subscription: {
        id: number,
        account_id: number,
        myne_payment_method_id: number | null,
        plan_id: number,
        expiration_date: string,
        created_at: string,
        updated_at: string,
        cancle_status: boolean | null,
        payment: boolean | null,
        status: string,
        stripe_subscription_id: string | null
    }
  } | null
  deleteAlertMessage: boolean;
  subs: {
    message: string,
    prompt: string,
    subscription: {
        id: number,
        account_id: number,
        myne_payment_method_id: number,
        plan_id: number,
        expiration_date: string,
        created_at: string,
        updated_at: string,
        cancle_status: boolean | null,
        payment: string | null,
        status: string,
        stripe_subscription_id: string | null
    }
  } | null;
  planParam:any;
  verifyCvvValue:any;
  cvvVerifyStatus: string;
  isLoader:boolean;
  isLoading: boolean;
  addressZipCodeInputFieldError: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FreetrialController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCountryCallId: string = "";
  addPaymentMethodFromEditCallId: string = "";
  getDetailsApiId: string = "";
  getAddressCallId: string = "";
  getCardsCallId: string = "";
  addAddressCallId: string = "";
  editAddressCallId: string = "";
  getPlanCallId: string = "";
  addPaymentMethodCallId: string = "";
  completePurchaseCallId: string = "";
  deleteCardCallId: string = "";
  getSubscriptionCallId: string = "";
  getSubsCallId: string ="";
  verifyCvvCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      logoImg1Login: localStorage.getItem('language') == "Arabic" ? "rtl" : "ltr",
      languagesLogin: localStorage.getItem('language') == "Arabic" ? "rtl" : "ltr",
      logoimg: logoimg,
      paymentMethod: 'credit_debit_card',
      countries: "",
      countryPage: 0,
      apiCallingStage: true,
      allCountriesLocation: [],
      countryName: '',
      isButtonClicked: false,
      deatailsData: [],
      freetrialid: null,
      changeplanid: null,
      monthlyid: null,
      yearlyid: null,
      address: '',
      token: localStorage.getItem('token'),
      paymentMethods: [],
      plan_id: '',
      selectedPaymentMethod: '',
      paymentMethodAddDetails: { cardName: '', expiry: '', cardNumber: '', cvv: '' },
      addAddressFormDetails: { name: '', address: '', city: '', zip_code: '', country: '' },
      saveCardForFuture: false,
      openSuccessAddress: false,
      openDeleteSuccess: false,
      openRejectedPopup: false,
      openFreeTrialSuccessPopup: false,
      selectedPlan1: '',
      card_id: '',
      selectedPlan: null,
      manageBtn: false,
      deleteErrorMsg: false,
      subscriptionDetails: null,
      deleteAlertMessage:false,
      subs: null,
      planParam:'',
      verifyCvvValue:'',
      cvvVerifyStatus: '',
      isLoader:false,
      isLoading: false,
      addressZipCodeInputFieldError: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId2 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson2 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (
      responseJson2 &&
      apiRequestCallId2 === this.getCountryCallId
    ) {

      if (responseJson2.length > 1) {
        const countries = responseJson2.map((value: any) => (
          { value: value.attributes.id, label: value.attributes.name }
        ));
        this.setState((prevState) => ({
          countries: [...prevState.countries, ...responseJson2],
          allCountriesLocation: [...prevState.allCountriesLocation, ...responseJson2],
          countryName: [...prevState.countryName, ...countries]

        }), () => {
          this.setState({ apiCallingStage: true })
        });
        this.callCountry()
      }

    }
    this.manageAddressResponse(responseJson2, apiRequestCallId2);
    this.managePaymentMethod(responseJson2, apiRequestCallId2)
    this.manageAddAddressResponse(responseJson2, apiRequestCallId2)
    this.manageGetPlanResponse(responseJson2, apiRequestCallId2)
    this.manageCompletePurchase(responseJson2, apiRequestCallId2)
    this.manageDeleteCardResponse(responseJson2, apiRequestCallId2)
    this.manageGetSubscription(responseJson2, apiRequestCallId2)
    this.manageSubscriptionResponse(responseJson2, apiRequestCallId2)
    this.manageVerifyCvvResponse(responseJson2, apiRequestCallId2)

    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId1 === this.getDetailsApiId) {

      if (responseJson1.data) {

        this.setState({ deatailsData: responseJson1.data })
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  handleNavigate() {
    if (this.state.freetrialid || this.state.monthlyid || this.state.yearlyid) {

      this.props.navigation.navigate("PaymentsFreeTrial", { id: this.state.freetrialid || this.state.monthlyid || this.state.yearlyid })
    }

  }
  handleNavigateChangePlan() {
    if (this.state.monthlyid || this.state.yearlyid) {
      this.props.navigation.navigate("PaymentsFreeTrial", { id: this.state.monthlyid || this.state.yearlyid })

    }

  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  getPlanName = ()=>{
    let plan = this.props.navigation.getParam('plan');
    this.setState({planParam:plan})
  }
  getStringLogin = (key: string) => {
    let languageLogin = localStorage.getItem("language") || "English"
    return configJSON.languageListLogin[languageLogin][key]
  }

  async checkboxHandle(value: string) {
    if(value === "credit_debit_card"){
      this.setState({deleteAlertMessage:false, deleteErrorMsg:false})
    }
    if (this.state.paymentMethod != value) {
      this.setState({ paymentMethod: value });
    }
  }
  handleTermsAndConditionsClick = () => {
    this.props.navigation.navigate("TermsConditions");
  }
  handleManageSubscriptionController() {
    if (localStorage.getItem("authToken")) {
      this.props.navigation.navigate("ManageSubscriptionController");
    }else{
      this.props.navigation.navigate("Apiintegrationbankaccounts");
    }
  }
  getCountriesForAddress = async () => {

    const requestMessageForCountries = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryCallId = requestMessageForCountries.messageId;

    requestMessageForCountries.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/get_countries?page=${this.state.countryPage + 1}`
    );
    this.setState({ countryPage: this.state.countryPage + 1 })

    requestMessageForCountries.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessageForCountries.id, requestMessageForCountries);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getAddress();
    this.getCountriesForAddress();
    this.getDeatilsJobsApplied();
    this.getCards();
    this.getPlan();
    this.getSubscription();
    this.getSubscriptionData();
    this.getPlanName()
  }
  callCountry = async () => {
    if (this.state.apiCallingStage) {
      this.setState({ apiCallingStage: false }, () => {
        this.getCountriesForAddress();
      })
    }
  }

  getDeatilsJobsApplied = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDetailsApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_stripe_integration/subscriptions/list_plan`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAddress = async () => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAddressCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/subscriptions/show_billing_address`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getSubscription = async () => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/subscriptions/get_subscription`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  manageAddressResponse = async (responseJson: any, apiRequestCallId: any) => {
    if (responseJson &&
      apiRequestCallId === this.getAddressCallId) {
      if (responseJson.data) {
        this.setState({ address: responseJson.data, paymentMethod:"credit_debit_card" })
      }else{
        this.setState({paymentMethod:"add_address"})
      }
    }
  }

  manageSubscriptionResponse = async (responseJson: any, apiRequestCallId: any) => {
    if (responseJson &&
      apiRequestCallId === this.getSubsCallId) {
      this.setState({ subs: responseJson })

    }
  }
  managePaymentMethod = async (responseJson: any, apiRequestCallId: any) => {
    if (responseJson &&
      apiRequestCallId === this.getCardsCallId) {
      if (responseJson?.data) {
        this.setState({ paymentMethods: responseJson.data })
      }
    }
  }
  manageAddAddressResponse = async (responseJson: any, apiRequestCallId: any) => {
    if (responseJson &&
      (apiRequestCallId === this.addAddressCallId || apiRequestCallId === this.editAddressCallId)) {
      if (responseJson.data) {
        this.setState({ paymentMethod: "apple_pay" })
        this.setState({ openSuccessAddress: !this.state.openSuccessAddress })
        this.getAddress()
      }
    }
  }
  manageGetPlanResponse = (responseJson: any, apiRequestCallId: any) => {
    if (responseJson &&
      apiRequestCallId === this.getPlanCallId) {
      if (responseJson.data) {
        this.setState({ selectedPlan1: responseJson.data })
      }
    }
  }
  editDetails = () => {
    this.setState({ addAddressFormDetails: { name: this.state.address.attributes.name, address: this.state.address.attributes.address, city: this.state.address.attributes.city, zip_code: this.state.address.attributes.zipcode, country: this.state.address.attributes.country }, paymentMethod: 'edit_address' })
  }

 zipCheck = (zipcode: string | number) => zipcode.toString().length ?  `&zipcode=${zipcode}`:''

  editAddress = () => {
    let cityRegex = /^[a-zA-Z\s]+$/;

    if (
      !this.state.addAddressFormDetails.name.trim().length
      || !this.state.addAddressFormDetails.country.length
      || !this.state.addAddressFormDetails.city.trim().length
      || !this.state.addAddressFormDetails.address.trim().length  
    ) {
      toast.dismiss()
      toast.error(this.getStringLogin('addAddressValidation'))
      return
    }
    if(this.state.addressZipCodeInputFieldError){
      return;
    }

    let addressNameRegx = new RegExp(/[^a-zA-Z\s]/g, '')

    let updatedAddressNameRegex = addressNameRegx.test(this.state.addAddressFormDetails.name)
    let cityName = this.state.addAddressFormDetails.city;



    if (updatedAddressNameRegex === true) {
      toast.dismiss()

      toast.error(this.getStringLogin('nameValidation'))

    }  else if (!cityRegex.test(cityName)) {
      toast.error(this.getStringLogin('CityValidation'));
    }
    else{
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editAddressCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/subscriptions/edit_billing_address?name=${this.state.addAddressFormDetails.name.trim()}&address=${this.state.addAddressFormDetails.address.trim()}&city=${this.state.addAddressFormDetails.city.trim()}${this.zipCheck(this.state.addAddressFormDetails.zip_code)}&country=${this.state.addAddressFormDetails.country}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);}
  }
  getPlan = () => {
    let plan_id = this.props.navigation.getParam('id');
    this.setState({ plan_id: plan_id })
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPlanCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/subscriptions/get_plan?plan_id=${plan_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  dialogCashPaperStyle = {
    width: 'max-content',
    backgroundColor: '#395D6B',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
  };
  dialogRejectedCashPaperStyle = {
    width: 'max-content',
    backgroundColor: '#6A3131',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
  };
  dialogCashModel = () => {
    this.setState({ openSuccessAddress: !this.state.openSuccessAddress })
  }
  deleteCardModel = ()=> {
    
    this.setState({ openDeleteSuccess:!this.state.openDeleteSuccess})
  }
  dialogRejectModel = ()=> {

    this.setState({openRejectedPopup: !this.state.openRejectedPopup })
  }
  getCards = async () => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCardsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/payment_methods`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  cvvVerify = (value:any)=>{
    if (value.length > 3) {
      return;
    }
    else{
      this.setState({verifyCvvValue:value})
    }
  }
  manageVerifyCvvResponse = (responseJson:any, apiRequestCallId:any)=>{
    if(responseJson && apiRequestCallId ==this.verifyCvvCallId){
      if(responseJson.error){
        toast.error(responseJson.error)
        this.getCards()
      }else
      if(responseJson.message){
        this.setState({verifyCvvValue:'', cvvVerifyStatus:'verified'})
        this.getCards()
      }
    }
  }
  verifyAPICalling = (cardData:any)=>{
    if(this.state.verifyCvvValue.length < 3){
      toast.error(this.getStringLogin('cvvValidation'))
    }
    else{
      const header = {
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.verifyCvvCallId = requestMessage.messageId;
      const formData = new FormData();
      formData.append("card_id",cardData.id);
      formData.append("cvv", this.state.verifyCvvValue);
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/payment_methods/verify_cvv`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData
        (getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  formatCardNumber = (value:any) => {
    return value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
  };
  updateAddCardDetails = async (name:any, value:any) => {
    if (name === "expiry" && value.length === 2 && value > 12) {
        return;
    }
    if (name === "cvv" && value.length > 3) {
        return;
    }
    if (name === "expiry" && value.length > 5) {
        return;
    }
    if (name === "cardNumber" && value.length > 19) {
        return;
    }
    this.setState((prevState) => {
        let updatedValue = value;
        if (name === "expiry" && value.length === 2) {
            updatedValue += "/";
        }

        if (name === "cardNumber") {
          updatedValue= this.formatCardNumber(value)
        }

        return {
            paymentMethodAddDetails: {
                ...prevState.paymentMethodAddDetails,
                [name]: updatedValue,
            },
        };
    });
};
  updateSaveCardState = () => {
    this.setState({saveCardForFuture: !this.state.saveCardForFuture})
  }
  buttonDisableStatus = () => {

    return false;
  }

  updateAddAddressDetails = async (name: any, value: any) => {
    if (name === "zip_code") {

      const regex = /^[0-9]{0,9}$/;

      if (value === "") {
        this.setState({
          addressZipCodeInputFieldError: false
        });
        this.setState((prevState) => {
          return {
            addAddressFormDetails: {
              ...prevState.addAddressFormDetails,
              [name]: value,
            },
          };
        });
        return;
      }

      if (!regex.test(value) || value.length > 9) {
        return;
      } else {
        this.setState({
          addressZipCodeInputFieldError: value.length < 4 ? true : false
        })
      }
    }
   
    if (name == 'country') {
      this.setState((prevState) => {
        return {
          addAddressFormDetails: {
            ...prevState.addAddressFormDetails,
            [name]: value.label,
          },
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          addAddressFormDetails: {
            ...prevState.addAddressFormDetails,
            [name]: value,
          },
        };
      });
    }
  };
  deleteCard = (value: any) => {
    if (this.state.paymentMethods.length == 1) {
      this.setState({ deleteErrorMsg: true, deleteAlertMessage: true })
      return;
    }

    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCardCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/payment_methods/${value.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setNewAddress = () => {
    if (
      !this.state.addAddressFormDetails.address.trim().length || 
      !this.state.addAddressFormDetails.city.trim().length || 
      !this.state.addAddressFormDetails.name.trim().length || 
      !this.state.addAddressFormDetails.country?.length 
    ) {
      toast.dismiss();
      toast.error(this.getStringLogin('addAddressValidation'));
      return;
    }

    if(this.state.addressZipCodeInputFieldError){
      return;
    }

    let addressRexName = new RegExp(/[^a-zA-Z\s]/g, '')
    let addressUpdateValue = addressRexName.test(this.state.addAddressFormDetails.name)
    if (addressUpdateValue === true) {
      toast.dismiss()
      toast.error(this.getStringLogin('nameValidation'))

    } else{
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAddressCallId = requestMessage.messageId;
    const formData = new FormData();
    formData.append("name", this.state.addAddressFormDetails.name);
    formData.append("address", this.state.addAddressFormDetails.address);
    formData.append("city", this.state.addAddressFormDetails.city);
    this.state.addAddressFormDetails.zip_code && formData.append("zipcode", this.state.addAddressFormDetails.zip_code);
    formData.append("country", this.state.addAddressFormDetails.country);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/subscriptions/billing_address`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData
      (getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  }
  manageCompletePurchase = (responseJson: any, apiRequestCallId: any) => {

    if (responseJson && apiRequestCallId == this.addPaymentMethodCallId) {
      if (responseJson.errors) {
        this.setState({isLoader:false})
        toast.error(responseJson.errors)
      }
      else {
        this.setState({ card_id: responseJson.data.id,isLoader:true })
        this.completePurchaseMainAPI()
      }
      this.setState({isLoading:false})
      this.getCards()
    }
    if (responseJson && apiRequestCallId == this.addPaymentMethodFromEditCallId) {
      this.setState({ paymentMethodAddDetails: { cardName: '', expiry: '', cardNumber: '', cvv: '' }, saveCardForFuture: false, cvvVerifyStatus:"", manageBtn:false })
      if(responseJson.message == "success"){
        toast.success(this.getStringLogin('primaryCardUpdated'))
        this.getCards();
      }else
      if (responseJson.error) {
        toast.error(responseJson.message)
        this.getCards();
      }
      else {
        toast.success(this.getStringLogin('cardAddSuccess'))
        this.getCards(); 
      }
    }
    this.manageCompletePurchase2(responseJson,apiRequestCallId)
  }
  manageCompletePurchase2 =  (responseJson: any, apiRequestCallId: any) => {
    if (responseJson && apiRequestCallId == this.completePurchaseCallId) {
      if(["Yearly plan can't be upgraded." , "You are already subscribed to this plan.", "Cannot upgrade to a trial plan.", "Cannot resubscribe to a trial plan.", "Yearly plan is already active.", "Plan is suspended. Kindly contact administrator for further details.","Your last subscription is failed due to technical reasons. Kindly contact administrator for further details.", "No canceled subscription found or already active."].includes(responseJson.message)){
        toast.error(responseJson.message)
      }
        else
      if (   responseJson.message != "Subscription upgraded successfully." && responseJson.message != 'Subscription added successfully.' && responseJson.message != 'Free trial subscription added successfully.'  && responseJson.message != "Re-subscription successful." && responseJson.message != "Plan is pending. Kindly allow some time to update your data.") {
        this.setState({ openRejectedPopup: !this.state.openRejectedPopup })
      }
      else {
        let plan = this.state.selectedPlan1.attributes.amount == 0 ? "trial" : "paid";
        this.setState({openFreeTrialSuccessPopup: true});
        this.props.navigation.navigate('SuccessScreenForPayment',{"plan":plan});
      }
      this.setState({ isLoading: false,isLoader:false,
        paymentMethodAddDetails: { cardName: '', expiry: '', cardNumber: '', cvv: '' }
        })
    }
  }
  manageDeleteCardResponse = (responseJson: any, apiRequestCallId: any) => {

    if (responseJson && apiRequestCallId == this.deleteCardCallId) {
      if (responseJson.message) {
        this.getCards()
        this.setState({ openDeleteSuccess: !this.state.openDeleteSuccess, paymentMethod: 'apple_pay' })
      }
    }
  }

  navigateBack = () => {
    if(!this.state.subs){
      return
    }

    if (this.state.subs?.message.startsWith("No subscription found")) {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'Freetrial');
      message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(message)
    } else {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), 'ChangePlan');
      message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(message)
    }
  }
  completePurchase = () => {
    if(this.state.paymentMethod.startsWith("dynamic_card") &&  this.state.paymentMethodAddDetails.cvv.trim().length < 3){
      toast.dismiss()
      toast.error(this.getStringLogin('cvvValidation'))
      return
    }
    if (this.state.address.length == 0) {
      toast.dismiss()
      toast.error(this.getStringLogin('billingAddressValidation'))
      return
    }
    if (this.state.paymentMethod == 'credit_debit_card') {
      if (!this.state.paymentMethodAddDetails.cardName.trim().length || !this.state.paymentMethodAddDetails.cvv.trim().length || !this.state.paymentMethodAddDetails.cardNumber.trim().length || !this.state.paymentMethodAddDetails.expiry.trim().length) {
        toast.dismiss()
        toast.error(this.getStringLogin('fillDetailsProperly'))
      }
      else{  
        this.setState({isLoading: true})      
      const header = {
        token: this.state.token
      };
      const requestMessageForCard = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addPaymentMethodCallId = requestMessageForCard.messageId;
      const formData = new FormData();
      let newValue = this.state.paymentMethodAddDetails.cardNumber.replace(/\s/g, '')

      formData.append("card_name", this.state.paymentMethodAddDetails.cardName);
      formData.append("card_number", newValue);
      formData.append("cvv", this.state.paymentMethodAddDetails.cvv);
      formData.append("expires_on", this.state.paymentMethodAddDetails.expiry);
      formData.append("primary", this.state.saveCardForFuture ? 'true':'false');

      requestMessageForCard.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/payment_methods`
      );

      requestMessageForCard.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageForCard.addData
        (getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
        );
      requestMessageForCard.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessageForCard.id, requestMessageForCard);
    }
    }
    else {
      this.setState({isLoader:true})
      this.completePurchaseMainAPI()
    }


  }


  handleCardToken = (cardToken: any) => {
    this.setState({isLoading: true})      
      const header = {
        token: this.state.token,
        "Content-Type": 'application/json',
      };
      const requestMessageForCard = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addPaymentMethodCallId = requestMessageForCard.messageId;

      requestMessageForCard.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/payment_methods`
      );

      requestMessageForCard.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessageForCard.addData
        (getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(cardToken)
        );
      requestMessageForCard.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessageForCard.id, requestMessageForCard);
};


  completePurchaseMainAPI = () => {
    const header = {
      token: this.state.token
    };
    let endpoint = "bx_block_stripe_integration/subscriptions/create_subscription"

    switch (this.state.subscriptionDetails?.prompt) {
      case "upgrade":
        endpoint = "bx_block_stripe_integration/subscriptions/upgrade_subscription_plan"
        break;
      case "re-subscribe":
        endpoint = "bx_block_stripe_integration/subscriptions/resubscribe"
        break;
      default:
        break;
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.completePurchaseCallId = requestMessage.messageId;
    const formData = new FormData();
    formData.append("plan_id", this.state.plan_id);
    formData.append("card_id", this.state.card_id);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData
      (getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  paymentMethodForDynamicCard = (values: any) => {
    this.checkboxHandle(`dynamic_card_${values.id}`)
    this.setState({ selectedPaymentMethod: values, card_id: values.id, verifyCvvValue:'', cvvVerifyStatus:'' })
  }
  manageBtn = (status: string) => {
    if(status == "manage"){
      this.setState({manageBtn: true})
    }
    if(status == "confirm" && this.state.cvvVerifyStatus !== "verified" && this.state.paymentMethod !== "credit_debit_card"){
      this.setState({manageBtn:false, verifyCvvValue:''})
      this.setState({deleteErrorMsg:false, deleteAlertMessage:false})
      return
    }
        
    if ((this.state.paymentMethod == 'credit_debit_card' && this.state.manageBtn) ) {  
      if (!this.state.paymentMethodAddDetails.cardName.trim().length || !this.state.paymentMethodAddDetails.cvv.trim().length || !this.state.paymentMethodAddDetails.cardNumber.trim().length || !this.state.paymentMethodAddDetails.expiry.trim().length) {
        toast.dismiss()
        toast.error(this.getStringLogin('fillDetailsProperly'))
      }
      else{
      const header = {
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addPaymentMethodFromEditCallId = requestMessage.messageId;
      const formData = new FormData();
      let newValue = this.state.paymentMethodAddDetails.cardNumber.replace(/\s/g, '')
      formData.append("card_name", this.state.paymentMethodAddDetails.cardName);
      formData.append("card_number", newValue);
      formData.append("cvv", this.state.paymentMethodAddDetails.cvv);
      formData.append("expires_on", this.state.paymentMethodAddDetails.expiry);
      formData.append("primary", this.state.saveCardForFuture ? 'true':'false');
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/payment_methods`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData
        (getName(MessageEnum.RestAPIRequestBodyMessage),
          formData 
        );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({manageBtn: false})
    }

    }else if(this.state.cvvVerifyStatus == 'verified' && this.state.manageBtn) {
      const header = {
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addPaymentMethodFromEditCallId = requestMessage.messageId;
      const primaryFormData = new FormData();
      primaryFormData.append("card_id", this.state.paymentMethod.replace('dynamic_card_', ''))
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/payment_methods/set_as_primary`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData
        (getName(MessageEnum.RestAPIRequestBodyMessage),
          primaryFormData
        );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    this.setState({ deleteErrorMsg: false })

  }
  manageGetSubscription = (responseJson: any, apiRequestCallId: any) => {
    if (responseJson && apiRequestCallId == this.getSubscriptionCallId) {
      this.setState({ subscriptionDetails: responseJson })
    }
  }
  getSubscriptionData = () => {
    const header = {
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_stripe_integration/subscriptions/get_subscription`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  dateFormat = (date:any)=>{
    const dateObject = new Date(date);

    // Extracting day, month, and year components
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Months are zero-based
    const year = dateObject.getFullYear();

    // Formatting the date in "DD/MM/YYYY" format
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  // Customizable Area End
}
