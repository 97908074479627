// Customizable Area Start
import React from "react";

import { groupArrow11, image_Imagenav_backicon, groupArrow, groupArrowOrange } from "./assets"
import FormApprovalWorkflowController, {
  Props,
} from "./FormApprovalWorkflowController.web";
import { Box, Typography, Button, styled, } from '@material-ui/core'
import storage from "../../../framework/src/StorageProvider";
import { convertMinutesToHoursAndMinutes } from "../../../components/src/commonFn";



export default class FormApprovalWorkflow extends FormApprovalWorkflowController {
  constructor(props: Props) {
    super(props);
  }
  handleBoxClick = (index: number) => {
    const { checkedBoxes } = this.state;
    const updatedCheckedBoxes = Array(checkedBoxes.length).fill(false);
    updatedCheckedBoxes[index] = true;
    this.setState({ checkedBoxes: updatedCheckedBoxes ,selectedBoxIndex: index, selectedPlan: this.getSubscriptionPlan(index) });
    const { packageList } = this.state;

    if (packageList && index >= 0 && index < packageList.length) {
        const selectedBoxData = packageList[index];
        storage.set("selectedBoxData", JSON.stringify(selectedBoxData));
        
    }
  };

  render() {
    const { checkedBoxes } = this.state;
    return (
      <div className="main-dashboard-wrapper"  dir={this.state.languagesLogin} style={{ width: '100%', scrollbarWidth: 'thin', scrollbarColor: '#00ebd9 transparent', height: '100vh', overflowY: 'scroll' }}>
        <WrapperStyle>
          <Box style={webStyle.profileNameNew}>
            <Box style={webStyle.planning}>{this.getStringTransactions('sessionName')}</Box>
          </Box>
          <Box><Typography style={webStyle.pageHeader}>{this.getStringTransactions('sessionTitle')}</Typography></Box>
          {this.state.packageList.length === 0 && (
            <Box>
              <Typography style={webStyle.noPlanAvailalble}>
              {this.getStringTransactions('noPlan')}
              </Typography>
            </Box>
          )}
          <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 30}} >
            {this.state.packageList.map((data: any, index: any) => (
              <Box key={index} style={{
                paddingTop: "12px", gap: 12, marginTop: '20px',
                flex: '0 0 47%', flexGrow: 1, maxWidth: "400px"
              }} onClick={() => this.handleBoxClick(index)} data-test-id="handleCheck">

                <Box style={webStyle.normalCardBox as React.CSSProperties} >
                  <Button variant="contained" style={{
                    position: 'absolute',
                    top: '-20px',
                    left: '50%',
                    transform: 'translate(-50%)',
                    borderRadius: 30,
                    background: this.getBackgroundColor(data.attributes.tier).bgColor,
                    color: 'white',
                    backgroundBlendMode: 'normal'
                  }}>{data.attributes.name}</Button>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: '18px', fontFamily: 'Poppins', fontWeight: 500 }}>{convertMinutesToHoursAndMinutes(data.attributes.duration)}</Typography>
                    <div style={{ position: 'relative', display: 'inline-block', marginRight: '10px' }}>
                      <input
                        type="checkbox"
                        checked={checkedBoxes[index]}
                        onChange={() => this.handleBoxClick(index)}
                        style={{ display: 'none' }}
                        data-test-id="handleCheck1"
                      />
                      <div
                        className="custom-checkbox"
                        style={{
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          border: '2px solid #ccc',
                          backgroundColor: checkedBoxes[index] ? '#007bff' : 'transparent',
                          cursor: 'pointer'
                        }}
                      />
                    </div>
                  </div>
                  <Typography style={{ fontSize: '22px', fontFamily: 'Poppins', fontWeight: 500 }}>{this.getCurrencySymbol(data.attributes.currency)}{data.attributes.amount}</Typography>

                  <PlanTextWrapper className="plan-txt-center">
                    {this.getStringTransactions('BenifitsOfPlan')}
                  </PlanTextWrapper>
                  <Box style={{ marginTop: '25px', display: 'flex', flexDirection: 'column', gap: '10px' }} >
                    {data.attributes.benefits.map((benefit: any, index: any) => (
                      <Box key={index} style={webStyle.profileNameNew}>
                        <Box data-test-id="backButton">
                          <img src={this.getBackgroundColor(data.attributes.tier).imgSrc} style={{...webStyle.marginImg1, maxWidth:"31px"}} alt="arrow" />
                        </Box>
                        <Box style={{...webStyle.planning1, wordBreak:"break-all" }}>{benefit}</Box>
                      </Box>
                    ))}
                  </Box>

                </Box>

              </Box>
            ))}
          </Box>
          {
            this.state.packageList.length > 0 && 
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <button style={webStyle.buttonContinue as React.CSSProperties} data-test-id="selectPlanPurchase"
              onClick={()=> this.navigateToCalenderPage() }>{this.getStringTransactions('continueBtn')}</button>
          </Box>
          }         
        </WrapperStyle>
      </div>
    );
  }
}

const WrapperStyle = styled(Box)({
  padding: '40px 41px 50px 110px',
  "@media (max-width: 767px)": {
    padding: '40px 40px 50px 40px', 
  }
})

const PlanTextWrapper = styled(Box)({
  "&.plan-txt-center": {
    display: "flex",
    flexDirection: "row",
    fontSize: '20px',
    fontFamily: 'Poppins', 
    fontWeight: 500
  },
  "&.plan-txt-center::before, &.plan-txt-center::after": {
    content: "''",
    flex: "1 1",
    borderBottom: "1px solid",
    margin: "auto",
  },
  "&.plan-txt-center::before": {
    marginRight: "10px",
  },
  "&.plan-txt-center::after": {
    marginLeft: "10px"
  }
})

const webStyle = {
  pageHeader: {
    color: '#FFF',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    marginTop: '12px',
    marginLeft: '2px'
  },
  pageHeader1: {
    color: '#FFF',
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal'
  },
  buttonContinue: {
    width: '100%',
    maxWidth: '456px',
    background: '#C9F9F6',
    border: '1px solid #707070',
    padding: '15px',
    borderRadius: '10px',
    color: '#3C3C50',
    fontSize: '18px',
    fontWeight: '700',
    fontFamily: 'Poppins',
    cursor: 'pointer',
    marginTop: '30px',
  },
  profileNameNew: { display: 'flex', alignItems: 'center' },
  planning: { fontSize: "25px", fontWeight: 600, color: "white" , margin:'3px'},
  marginImg: { margin: '0px', cursor: "pointer" },
  planning1: { fontSize: "18px", color: "black", fontFamily: 'Poppins', fontWeight: 400 },
  marginImg1: { marginRight: '10px', cursor: "pointer" },

  normalCardBox: {
    cursor: 'pointer',
    backgroundColor: 'white',
    borderRadius: '12px',
    border: '1px solid #2675ff',
    padding: '24px 27px',
    position: 'relative',
    height: "100%"
  },
  noPlanAvailalble:{ fontSize: "18px", fontWeight: 600, color: "white" , fontFamaily:"Poppins"}




}

// Customizable Area End
