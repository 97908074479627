// Customizable Area Start
import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    OutlinedInput,
    Button,
    styled
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from 'react-select';
import { image_Imagenav_backicon, plus1, card, apple, SuccessCard, close } from "./assets";
import ServicePaymentController, { Props } from "./ServicePaymentController.web";
import StripePayment from "../../../components/src/StripeForm/StripePaymentForm";


// Customizable Area End

// Customizable Area Start
const cashCustomStyles = {
    control: (defaultStyles: any) => (
        {
            ...defaultStyles,
            textAlign: "left",
            fontSize: '16px',
            color: 'rgba(255, 255, 255, 0.60)',
            backgroundColor: "transparent !important",
            border: '1px solid #2DF1E3 !important',
            fontWeight: '500',
            padding: "8px",
            fontFamily: 'Poppins',
            borderRadius: '8px',
        }),
};

const theme = createTheme({
    palette: {
        primary: {
            main: "#2CE2D5",
            contrastText: "#fff",
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    borderColor: "#2DF1E3",
                },
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#2DF1E3",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2DF1E3 !important",
                },
                "& .MuiOutlinedInput-input": {
                    color: "white",
                    width: '100%',
                    borderBottom: '0px',
                }
            },
        },
    },
});
// Customizable Area End

export default class PaymentService extends ServicePaymentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    selectMenu(customStyles: any) {
        let value = { value: "", label: "" };
        if (this.state.paymentMethod == 'edit_address') {
            value = { value: this.state.addAddressFormDetails?.country, label: this.state.address?.attributes?.country }
        }
        return (
            <Select
                options={this.state.countryName} defaultValue={value}
                onChange={(event) => this.updateAddAddressDetails('country', event)}
                classNamePrefix="an-simple-select" className="selectMenu" data-test-id="selectMenu" styles={customStyles} />
        )
    }

    formRender(className: string) {
        return (
            <AddressFormWrapper id="credit_card_credential_id" className={`point ${ this.state.languagesLogin === 'rtl' ? 'rtlabsolute':  className}`}>
                {
                    this.state.paymentMethod == 'credit_debit_card' &&
                    <>
                        <Box>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={card} style={{ width: '47px' }} />
                                <Typography style={webStyle.formHeader}>{this.getStringService('CreditDebitCard')}</Typography>
                            </Box>
                            <Box style={webStyle.credit_card_form}>
                            <StripePayment handleToken={this.handleCardToken} />
                            </Box>

                        </Box>
                    </>
                }
{
                    this.state.paymentMethod == 'add_address' &&
                    <Box>
                        <Box className="add_address_form" >
                            <Box id="add_address_label" style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={webStyle.formHeader}>{this.getStringService('AddAddress')}</Typography>
                            </Box>
                        </Box>
                        <Box style={webStyle.address_form} className="form_body">
                            <Grid container spacing={2} className="add_Address_grid">
                                <Grid item xs={12} id="name_label">
                                    <Typography style={webStyle.card_input_message}>{this.getStringService('Name')}</Typography>
                                    <OutlinedInput
                                        className="inputBox"
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        data-test-id="addNameInput"
                                        placeholder={this.getStringService('enterName')}
                                        onChange={(event) => this.updateAddAddressDetails('name', event.target.value)}
                                        fullWidth={true} required
                                        defaultValue={this.state.addAddressFormDetails.name}
                                        value={this.state.addAddressFormDetails.name}

                                    />
                                </Grid>
                                <Grid item xs={12} id="addressFieldLabel">
                                    <Typography style={webStyle.card_input_message}>{this.getStringService('address')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        fullWidth={true}
                                        data-test-id="addAddressInput"
                                        onChange={(event) => this.updateAddAddressDetails('address', event.target.value)}
                                        placeholder={this.getStringService('enterAddress')} required
                                        defaultValue={this.state.addAddressFormDetails.address}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="cityLabel">
                                    <Typography style={webStyle.card_input_message}>{this.getStringService('city')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        data-test-id="addCityInput"
                                        onChange={(event) => this.updateAddAddressDetails('city', event.target.value)}
                                        fullWidth={true}
                                        placeholder={this.getStringService('enterCity')} required
                                        defaultValue={this.state.addAddressFormDetails.city}
                                        value={this.state.addAddressFormDetails.city}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="zipcodeBlock">
                                    <Typography style={webStyle.card_input_message}>{this.getStringService('zipCode')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        data-test-id="addZipCodeInput"
                                        className="inputBox"
                                        onChange={(event) => this.updateAddAddressDetails('zip_code', event.target.value)}
                                        fullWidth={true}
                                        placeholder={this.getStringService('EnterZipCode')} 
                                        defaultValue={this.state.addAddressFormDetails?.zip_code}
                                        inputProps={{ maxLength: 9 }}
                                  
                                    />
                                </Grid>
                                <Grid item xs={12} id="countryLabel">
                                    <Typography style={webStyle.card_input_message}>{this.getStringService('selectCountry')}</Typography>
                                    <Box style={{ marginTop: '12px' }}>
                                        {(this.selectMenu(cashCustomStyles))}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography data-test-id="addNewAddress" 
                                    onClick={() => this.setNewAddress()}
                                     style={{ ...webStyle.confirmBtnMessage, cursor: 'pointer', float: 'right' }}>{this.getStringService('confirmDetails')}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }
                {
                    this.state.paymentMethod == 'edit_address' &&
                    <>
                        <Box>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={webStyle.formHeader}>{this.getStringService('EditAddress')}</Typography>
                            </Box>
                        </Box>
                        <Box style={webStyle.address_form}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <Typography style={webStyle.card_input_message}>{this.getStringService('Name')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        data-test-id="editAddressName"
                                        className="inputBox"
                                        fullWidth={true}
                                        onChange={(event) => this.updateAddAddressDetails('name', event.target.value)}
                                        defaultValue={this.state.addAddressFormDetails.name}
                                        placeholder={this.getStringService('enterName')}
                                        value={this.state.addAddressFormDetails.name}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography style={webStyle.card_input_message}>{this.getStringService('address')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        data-test-id="editAddressAddress"
                                        fullWidth={true}
                                        onChange={(event) => this.updateAddAddressDetails('address', event.target.value)}
                                        defaultValue={this.state.addAddressFormDetails.address}
                                        placeholder={this.getStringService('enterAddress')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Typography style={webStyle.card_input_message}>{this.getStringService('city')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        fullWidth={true}
                                        data-test-id="editAddressCity"
                                        onChange={(event) => this.updateAddAddressDetails('city', event.target.value)}
                                        defaultValue={this.state.addAddressFormDetails.city}
                                        placeholder={this.getStringService('enterCity')}
                                        value={this.state.addAddressFormDetails.city}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Typography style={webStyle.card_input_message}>{this.getStringService('zipCode')}</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        data-test-id="editAddressZipCode"
                                        fullWidth={true}
                                        onChange={(event) => this.updateAddAddressDetails('zip_code', event.target.value)}
                                        defaultValue={this.state.addAddressFormDetails?.zip_code}
                                        placeholder={this.getStringService('EnterZipCode')}
                                        inputProps={{ maxLength: 9 }}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography style={webStyle.card_input_message}>{this.getStringService('selectCountry')}</Typography>
                                    <Box style={{ marginTop: '12px' }}>
                                        {(this.selectMenu(cashCustomStyles))}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography style={{ ...webStyle.confirmBtnMessage, cursor: 'pointer', float: 'right' }} data-test-id="editConfirmDetails" onClick={() => this.editAddress()}>{this.getStringService('confirmDetails')}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                }
            </AddressFormWrapper>
        )
    }

    addressData() {
        return (
            <Box>
                <Typography style={this.state.paymentMethod == 'edit_address' ? webStyle.billingAddressFontActive : webStyle.billingAddressFont}>{this.state.address?.attributes?.name}</Typography>
                <Typography style={this.state.paymentMethod == 'edit_address' ? webStyle.billingAddressFontActive : webStyle.billingAddressFont}>{this.state.address?.attributes?.address}</Typography>
                <Typography style={this.state.paymentMethod == 'edit_address' ? webStyle.billingAddressFontActive : webStyle.billingAddressFont}>{this.state.address?.attributes?.city + ", " + this.state.address?.attributes?.zipcode + ", " + this.state.address?.attributes?.country}</Typography>
            </Box>
        )
    }

    getServicesRTL(){
        if(this.state.languagesLogin === 'rtl'){
            return`${this.state.serviceDate?.services?.length} ${this.state.serviceDate?.services?.length === 1 ? this.getStringService('Service') : this.getStringService('Services')}`
        }else{
            return `${this.state.serviceDate?.services?.length} Service${this.state.serviceDate?.services?.length === 1 ? '' : 's'}`
        }
    }

    getRightPosition = () => this.state.languagesLogin === 'rtl' ? '' : '23px';
    getLeftPosition = () => this.state.languagesLogin === 'rtl' ? '23px' : '';
    getContainerMargin = () => this.state.languagesLogin === 'rtl' ? '30px 27px 0px 0px':'30px 0px 0px 27px';
    getSummaryPadding =()=> this.state.languagesLogin === 'rtl' ? '0px 0px 0px 50px ':'0px 50px 0px 0px';
    cvvBoxPadding =()=>this.state.languagesLogin === 'rtl' ? '20px 30px 0 10px':'20px 10px 0 30px';
    

    render() {
        return (
            // Customizable Area Start
            <Box style={{ width: '100%', scrollbarWidth: 'thin', scrollbarColor: '#00ebd9 transparent', height: '100vh', overflowY: 'scroll' }} dir={this.state.languagesLogin}>
                <Box style={{ marginBottom: '2rem' }} >
                    <Box >
                        <Box >
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <Box style={{ display: 'flex', alignItems: 'center', margin:this.getContainerMargin() }}>
                                    <NavLink to="/EstatePlanning"><img src={image_Imagenav_backicon} alt="back-icon" style={{transform: this.state.languagesLogin === 'rtl' ? 'scaleX(-1)':'' }} /></NavLink>
                                    <Typography style={webStyle.customLabel}> {this.getStringService('ProceedPayment')}</Typography>

                                </Box>

                                <Grid id="container" container spacing={2} style={{ margin: '20px', position: 'relative', width: '97%' }}>
                                    <Grid item xs={12} sm={12}>
                                        <SummaryWrapper style={{padding: this.getSummaryPadding()}}>

                                            <Typography style={webStyle.customLabel22}>{this.getStringService('Summary')}</Typography>
                                            <Box style={{ marginTop: '22px' }}>
                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '0.5rem' }}>
                                                    <Typography style={webStyle.customLabel3}> {this.getStringService('YourService')}</Typography>
                                                    <Typography style={webStyle.customLabel4}>
                                                    {this.getServicesRTL()}
                                                    </Typography>
                                                </Box>
                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Typography style={webStyle.customLabel3}>{this.getStringService('Payment')}</Typography>
                                                    <Typography style={{...webStyle.customLabel3, color: 'white'}}>{`${this.state.currency} ${this.state.sub_total}`}</Typography>
                                                </Box>
                                                <hr color="#928F98" />
                                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Typography style={webStyle.customLabel3}>{this.getStringService('TotalCharge')}</Typography>
                                                    <Typography style={webStyle.customLabel5}>{`${this.state.currency} ${this.state.sub_total}`}</Typography>
                                                </Box>
                                            </Box>
                                        </SummaryWrapper>
                                    </Grid>

                                    <Grid item xs={12} sm={12} style={{minHeight: '48vh'}}>
                                        <Box style={{ marginBottom: '20px', position: 'relative', }}>

                                            {this.state.address ?
                                                <Box style={{ marginTop: '24px', padding: this.getSummaryPadding(), width: '45%' }}>
                                                    <Box style={webStyle.editAddressBox}>
                                                        <Typography style={webStyle.customLabel2}>{this.getStringService('BillingAddress')}</Typography>
                                                        {this.state.paymentMethod != 'edit_address' &&
                                                            <Typography style={webStyle.editDetailsFont} data-test-id="editDetailsBtn" onClick={() => this.editDetails()}>{this.getStringService('EditDetails')}</Typography>
                                                        }
                                                    </Box>
                                                    {this.addressData()}

                                                </Box>:
                                                <BillingAddressWrapper>
                                                <Typography style={webStyle.customLabel2}>{this.getStringService('BillingAddress')}</Typography>
                                                <Box data-test-id="addAddressBtn" onClick={() => { this.checkboxHandle('add_address') }} style={{ ...webStyle.addressBtn, display: 'flex', alignItems: 'center', width:'100%' }}>
                                                    <img src={plus1} style={{ width: '16px', height: '16px', marginLeft: '10px' }} />
                                                    <Typography style={webStyle.customLabel6}>{this.getStringService('AddNewAddress')}</Typography>

                                                </Box>
                                                    {this.formRender('relative')}

                                            </BillingAddressWrapper>
                                            }
                                            <PaymentWrapper>
                                                <Typography style={webStyle.customLabel2}>{this.getStringService('PaymentMethod')}</Typography>
                                                {this.state.paymentMethods &&
                                                    this.state.paymentMethods.map((values: any) => {
                                                        return (
                                                            <Box id="payment_card_method" style={{ ...webStyle.addressBtn2, display: 'flex', position: 'relative', alignItems: 'center' }} data-test-id={`dynamic_card_${values.id}`} className={this.state.paymentMethod == `dynamic_card_${values.id}` ? 'activeBorder point' : ""} onClick={() => this.paymentMethodForDynamicCard(values)}>
                                                                {values.attributes.primary_card &&
                                                                    <div style={{ position: 'absolute', top: '-11px', right: this.getRightPosition(), left: this.getLeftPosition(), background: '#814FF9', borderRadius: '5px', padding: '1px 10px', fontSize: '12px', color: '#fff', }}>{this.getStringService('Primary')}</div>
                                                                }
                                                                <div className="main-radio-box">
                                                                    <div className="radio-box2" >
                                                                        <input type="radio" className="radio-button" value="search_engine" checked={this.state.paymentMethod === `dynamic_card_${values.id}`} />
                                                                        <label>
                                                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <img src={card} style={{ width: '47px' }} />
                                                                                <Box>
                                                                                    <Typography style={webStyle.cardNameLabel}>{values.attributes.card_name + ' ' + values.attributes.card_number}</Typography>
                                                                                    <Typography style={webStyle.cardExpireLabel}>{`${this.getStringService('ExpiresOn')} ${values.attributes.expires_on}`}</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </label></div>
                                                                </div>
                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </PaymentWrapper>
                                          
                                            <CardWrapper data-test-id="credit-debit-card-btn" className={this.state.paymentMethod == 'credit_debit_card' ? 'activeBorder' : ""} onClick={() => this.checkboxHandle('credit_debit_card')}>
                                                <div className="main-radio-box">
                                                    <div className="radio-box2" data-test-id="radioBox1"><input type="radio" className="radio-button" value="search_engine" checked={this.state.paymentMethod === "credit_debit_card"} /><label>
                                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img src={card} style={{ width: '47px' }} />
                                                            <Typography style={{ ...webStyle.customLabel7, cursor: "pointer" }}>{this.getStringService('CreditDebitCard')}</Typography>
                                                        </Box>
                                                    </label></div>
                                                </div>
                                            </CardWrapper>
                                            {
                                                this.state.paymentMethod.includes('dynamic_card') &&
                                                <>
                                                    <Box>
                                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img src={card} style={{ width: '47px' }} />
                                                            <Typography style={webStyle.formHeader}>{this.state.selectedCardDetails.card_name + " " + this.state.selectedCardDetails.card_number}</Typography>
                                                            <Typography style={webStyle.formHeader2}>{`${this.getStringService('ExpiresOn')} ${this.state.selectedCardDetails.expiry}`}</Typography>
                                                        </Box>
                                                        <AuthWrapper style={{padding:this.cvvBoxPadding() }}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Typography style={webStyle.card_input_message}>{this.getStringService('AuthWithCVV')}</Typography>
                                                                    <OutlinedInput
                                                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px', marginBottom: '16px', width: '120px' }}
                                                                        className="inputBox"
                                                                        fullWidth={true}
                                                                        placeholder="000"
                                                                        value={this.state.saveCvvInfo}
                                                                        onChange={this.handleStoreCvvInfo}
                                                                        data-test-id="storeCvv"
                                                                    />
                                                                </Grid>
                                                            </Grid>

                                                        </AuthWrapper>

                                                    </Box>
                                                </>
                                            }

                                        </Box>


                                    </Grid>
                                    {this.formRender('absolute')}

                                </Grid>

                            </ThemeProvider>

                        </Box>

                    </Box>

                </Box>
                {
                    this.handleIsProcessPaymentVisible() &&
                    <Box data-test-id="completePurchaseBtn" onClick={() => this.completeServicePayment()} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Box>
                            <Button data-test-id="success-page" style={{ ...webStyle.buttonContinue as React.CSSProperties, textTransform: 'unset' }}>{this.getStringService('continueBtn')}</Button>
                        </Box>
                    </Box>
                }
                <Box>
                    <Dialog
                        open={this.state.openSuccessAddress}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={this.dialogCashModel}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{
                            style: {
                                width: 'max-content',
                                backgroundColor: '#395D6B',
                                borderRadius: '8px',
                                minWidth: "49%",
                                boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
                            }
                        }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
                        <DialogContent style={{ padding: '8px 20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <DialogContentText id="alert-dialog-slide-description" className='dialogContent'>
                                {this.getStringService('AddressUpdateSuccess')}
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>

                </Box>
            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const SummaryWrapper = styled(Box)({
    width: "45%",
    paddingRight: '50px',
    "@media (max-width: 767px)": {
        width: "100%"
    }
})

const BillingAddressWrapper = styled(Box)({
    width: "41.5%",
    marginTop: '24px', 
    paddingRight: '0px',
    "@media (max-width: 767px)": {
        width: "100%",
        paddingRight: "50px"
    }
})

const ApplePayWrapper = styled(Box)({
    borderRadius: "12px",
    border: '1px solid #928F98',
    height: '50px',
    display: 'flex', 
    cursor: "pointer", 
    alignItems: 'center',
    width: "41.5%",
    marginTop: '24px', 
    paddingRight: '0px',
    "@media (max-width: 767px)": {
        width: "calc(100% - 50px)",
    }
})

const PaymentWrapper = styled(Box)({
    width: "41.5%",
    paddingRight: '0px',
    marginTop: '24px', 
    "@media (max-width: 767px)": {
        width: "100%",
        paddingRight: "50px"
    }
})

const AuthWrapper = styled(Box)({
    width: "41.5%",
    borderRadius: '12px',
    border: '1px solid #2DE2D5',
    boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.66)',
    marginTop: '22px',
    padding:'20px 10px 0 30px',
    paddingRight: '0px', 
    "@media (max-width: 767px)": {
        width: "calc(100% - 50px)",
    }
})

const CardWrapper = styled(Box)({
    width: "41.5%",
    borderRadius: "12px",
    border: '1px solid #928F98',
    height: '50px',
    marginTop: '15px',
    cursor: 'pointer',
    display: 'flex', alignItems: 'center',
    "@media (max-width: 767px)": {
        width: "calc(100% - 50px)",
    }
})

const AddressFormWrapper = styled(Box)({
    "&.absolute": {
        position: 'absolute',
        right: '3rem', 
        top: '8rem'
    },
    "&.rtlabsolute": {
        position: 'absolute',
        left: '3rem', 
        top: '8rem'
    },
    "&.relative": {
        display: "none"
    },
    "@media (max-width: 950px)": {
        "&.absolute": {
            display: "none"
        },
        "&.relative": {
            display: "block",
            position: "relative"
        }
    }
})

const webStyle = {
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "red !important",
        }
    },
    line: {
        width: '5px',
        height: '100%',
        background: 'red',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '::after': {
            content: '" "',
            width: '100px',
            height: '5px',
            background: 'green'
        }
    },
    normalCardBox: {
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '12px',
        border: '1px solid #2675ff',
        padding: '24px 27px',
        position: 'relative',
        marginTop: '38px',
    },
    profileNameNew: { display: 'flex', alignItems: 'center' },
    marginImg1: { marginRight: '10px', cursor: "pointer" },
    planning1: { fontSize: "18px", color: "black", fontFamily: 'Poppins', fontWeight: 400 },
    buttonContinue: {
        width: '15rem',
        background: '#C9F9F6',
        border: '1px solid #707070',
        padding: '15px',
        borderRadius: '10px',
        color: '#3C3C50',
        marginBottom:"30px",
        fontSize: '18px',
        fontWeight: '700',
        fontFamily: 'Poppins',
        cursor: 'pointer',
        marginTop: '30px',
    },
    save_card_msg: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Poppins',
    },
    confirmPurchaseBtn: {
        backgroundColor: '#C9F9F6',
        display: 'flex',
        width: '456px',
        height: '56px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
    },
    card_input_message: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 300,
        fontFamily: 'Poppins',
    },
    credit_card_form: {
        borderRadius: '12px',
        border: '1px solid #2DE2D5',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.66)',
        width: '504px',
        marginTop: '22px',
        padding:'20px'
    },
    address_form: {
        width: '340px',
    },
    customLabel: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '24px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        marginLeft: "10px",
        marginRight: "10px",
    },
    billingAddressFontActive: {
        color: '#2CE2D5',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        marginBottom: '0.2rem'
    },
    billingAddressFont: {
        color: '#928F98',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        marginBottom: '0.2rem'
    },
    confirmBtnMessage: {
        color: '#C9F9F6',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    card_message: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '10px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        margin: '0px 7px',

    },
    customLabel2: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    customLabel22: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        marginTop: '24px',
    },
    editAddressBox: {
        display: 'flex',
        justifyContent: ' space-between',
        alignItems: "center",
        marginBottom: '0.5rem'
    },
    editDetailsFont: {
        color: '#814FF9',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        cursor: 'pointer',
    },
    customLabel3: {
        color: '#928F98',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    customLabel4: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    customLabel5: {
        color: '#2CE2D5',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    customLabel6: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '12px 12px',
    },
    customLabel7: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '12px 7px',
    },
    cardNameLabel: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '0px 7px',

    },
    cardExpireLabel: {
        color: 'rgba(255, 255, 255, 0.50)',
        fontStyle: 'normal',
        fontSize: '10px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        margin: '0px 7px',
    },
    formHeader: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '12px 7px',
    },
    formHeader2: {
        color: '#928F98',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        margin: '12px 7px',
    },


    inputRootForPayments: {
        borderRadius: '10px',
        height: '56px',
        fontFamily: 'Poppins',
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },
        '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
            padding: '14.5px 14px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2DF1E3 !important',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2DF1E3 !important',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2DF1E3 !important',
        },
        '& input::placeholder': {
            fontWeight: 500,
            fontSize: '12px',
            color: 'rgba(255, 255, 255, 0.60)',

        }, '& .MuiInputBase-input': {
            borderColor: '#2DF1E3 !important'
        },
    },
    cekbox: {
        '& .MuiCheckbox-root': {
            color: 'aqua',
        },
        '& .MuiIconButton-root': {
            padding: '0px'
        }
    },

    buttons: {
        backgroundColor: "#c7f9f6",
        borderRadius: '10px',
        margin: '0px',
        Color: '#3C3C50',
        height: "50px",
        fontWeight: 700,
        fontSize: '20px',
        textTransform: 'none',
    },
    addressBtn: {
        borderRadius: "12px",
        border: '1px solid #928F98',
        width: '45%',
        height: '50px',
        marginTop: '22px',
        cursor: 'pointer',
    },
    addressBtn2: {
        borderRadius: "12px",
        border: '1px solid #928F98',
        width: '100%',
        height: '50px',
        marginTop: '15px',
        cursor: 'pointer',
    },
    logoandimg: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        paddingTop: '46px',
        paddingRight: '49px',
        paddingLeft: '49px'
    },
    fieldError: {
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
    }
};
// Customizable Area End
// Customizable Area End
