import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import { openIcon, image_Imagenav_backicon, modelSuccess } from "./assets"
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import './planner.css';
import FinancialPlannerController, {
    Props
} from "./FinancialplannerController";
import { getFirstCharacterFromEachWord } from "../../../components/src/commonFn";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export default class FinancialPlanner extends FinancialPlannerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    showNameData = (value: any) => {
        return (
            <>
                {this.state.showName ? value.attributes.email : this.maskEmail(value.attributes.email)}&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.showName ? (
                    < VisibilityOutlinedIcon style={webStyle.handleClickMargin} data-test-id="handleIconClick1New" onClick={(e) => this.handleIconClick(e, value.id)} />
                ) : (
                    <VisibilityOffOutlinedIcon style={webStyle.handleClickMargin} data-test-id="handleIconClick2New" onClick={(e) => this.handleIconClick(e, value.id)} />
                )}
            </>
        )
    }
    isUpData = () => this.state.isUp === false ? "" : "none";

    showDataNone = () => this.state.show ? '' : 'none';

    showMoreData = (value: string) => this.state.show === value ? this.getStringFinancialPlanner("Less") : this.getStringFinancialPlanner("More");

    showNoneData = (value: string) => this.state.show === value ? '' : 'none';


    visibilityOutlinedIcon = (value: any) => {
        return (
            <>
                {this.state.showName && value.id === this.state.selectedId ? value.attributes.email : this.maskEmail(value.attributes.email)}&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.showName && value.id === this.state.selectedId ? (
                    < VisibilityOutlinedIcon style={webStyle.handleClickMargin} data-test-id="handleIconClick1" onClick={(event) => this.handleIconClick(event, value.id)} />
                ) : (
                    <VisibilityOffOutlinedIcon style={webStyle.handleClickMargin} data-test-id="handleIconClick2" onClick={(event) => this.handleIconClick(event, value.id)} />
                )}
            </>
        )
    }
    isClickedIcon = () => {
        return this.state.isClicked ? <NextButton data-test-id="nextButton" onClick={() => this.nextButton(this.state.isClicked)}>{this.getStringFinancialPlanner("Next")}</NextButton> : <NextButtonNew >{this.getStringFinancialPlanner("Next")}</NextButtonNew>
    }
    isUpIcon = (value: any) => {
        return value.attributes.total_reviews ?
            <IconButton data-test-id="handleClickNewData" onClick={(e) => { this.handleClick(e) }}>
                {this.state.isUp ? <ExpandLessRoundedIcon style={webStyle.expandMoreRounded} /> : <ExpandMoreRoundedIcon style={webStyle.expandMoreRounded} />}
            </IconButton> :
            null
    }
    clickedNone = (value: string) => {
        return this.state.isClicked === value ? webStyle.clicked : webStyle.webStyleMenBox;
    }
    average_rating_Data_Value = (value: any) => {
        return value.attributes.average_rating ?
            <>
                <Typography style={webStyle.average_rating_fontSize} className="">{value.attributes.average_rating}</Typography>&nbsp;&nbsp;
                <Box style={webStyle.fontSizeText} className="">
                    <Rating name="size-small" emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor} />} value={value.attributes.average_rating} readOnly precision={0.5} size="small" />
                </Box>
            </> :
            null
    }

    average_rating_rating = (value: any) => {
        return value.attributes.average_rating ? <Rating emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor} />} name="size-small" value={value.attributes.average_rating} readOnly precision={0.5} size="small" /> : null
    }

    total_reviews_reviews = (value: any) => {
        return value.attributes.total_reviews ? `${this.getStringFinancialPlanner("basedoutof")} ${value.attributes.total_reviews} ${this.getStringFinancialPlanner("reviews")}` : this.getStringFinancialPlanner("noReviews")
    }
    messengDataSuccessfully = () => {
        return this.state.messengData ? this.state.messengData : <span>{this.getStringFinancialPlanner("successMessage")}<br /> {this.getStringFinancialPlanner("notifyMessage")}</span>
    }
    renderProfile = (userImage: string, userName: string, imgSize: string, fontSize?: string) => {
        return userImage ?
            <img src={userImage} width={imgSize} height={imgSize} style={{objectFit:'cover', borderRadius:'15px'}} />
            :
            <div className="round-div">
                {getFirstCharacterFromEachWord(userName)}
            </div>
    }

    checkCondition = (condition: boolean, truthyValue: string, falsyValue: string) => {
        return condition ? truthyValue : falsyValue
    }
    render() {
        return (
            // Customizable Area Start
            <>
                <BackgroundBoxSatyle dir={this.state.direction}>
                    {this.state.numberFind ?
                        <CnvestmentBox>
                            <Box>
                                <Box style={webStyle.profileNameNew}>
                                    <Box data-test-id="backButton" onClick={this.backButton}><img src={image_Imagenav_backicon} style={webStyle.marginImg} /></Box> <Box style={webStyle.planning}>{this.getStringFinancialPlanner("EstatePlanning")}</Box>
                                </Box>
                                <Box style={webStyle.listLine}>{this.getStringFinancialPlanner("selectPlannerDesc")}</Box>
                                <Box>
                                    {this.state.FindData.map((value: any, index: number) => {
                                        return (
                                            <Box key={index} style={webStyle.webStyleMenBox} className="webStyleMenBox">
                                                <Box style={webStyle.planningBackground}>
                                                    <Box className="planner-content">
                                                        <Box style={{
                                                            display: 'flex',
                                                            gap: '10px'
                                                        }}>
                                                            {this.renderProfile(value.attributes.image, value.attributes.name, "94px")}
                                                            <Box style={webStyle.boxMargin}>
                                                                <TextLable>{value.attributes.name}</TextLable>
                                                                <Box className="" style={webStyle.textlabel_NameOfCorporation}>{this.getStringFinancialPlanner("NameofCorporation")}:&nbsp;<Textlabel_LosAngeles>{value.attributes.firm}</Textlabel_LosAngeles>

                                                                </Box>

                                                                <Box className="textlabel_NameOfCorporation" style={webStyle.textlabel_NameOfCorporation}>{this.getStringFinancialPlanner("Location")}:&nbsp;<Textlabel_LosAngeles>{value.attributes.location}</Textlabel_LosAngeles></Box>
                                                                <Box style={webStyle.textlabel_NameOfCorporation}>{this.getStringFinancialPlanner("JurisdictionAdvise")}:&nbsp;<Textlabel_LosAngeles>{value.attributes.jurisdiction_advice}</Textlabel_LosAngeles></Box>
                                                            </Box>

                                                        </Box>

                                                        <Box style={webStyle.boxMargin}>
                                                            <Box style={{ ...webStyle.textlabel_NameOfCorporation, marginTop: "22px" }}>{this.getStringFinancialPlanner("Experience")}:&nbsp;<Textlabel_LosAngeles>{value.attributes.experience} {this.checkCondition(value.attributes.experience > 1, this.getStringFinancialPlanner("Yrs"), this.getStringFinancialPlanner("Yr"))}</Textlabel_LosAngeles></Box>
                                                            <Box className="" style={webStyle.profileNameNew}>
                                                                <Box style={{ ...webStyle.average_rating_fontSize, marginLeft: "auto" }} className="">{value.attributes.average_rating}</Box>&nbsp;&nbsp;
                                                                <Box style={{ ...webStyle.fontSizeText, marginLeft: "0" }} className="">
                                                                    {value.attributes.average_rating ? <Rating name="size-small" value={value.attributes.average_rating} readOnly precision={0.5} size="small" emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor} />} /> : null}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>


                                                    <Box>
                                                        <Box className="focusLable" style={webStyle.focusLable}>{this.getStringFinancialPlanner("Focus")}:</Box>

                                                        <MinBoxDisplayNew className="minBoxDisplayNew">
                                                            {value.attributes.focus.map((valueButton: any, index: number) => { return <ButtonStyle key={index} className="nameButton">{valueButton.name}</ButtonStyle> })}
                                                            <Box style={{ ...webStyle.textlabel_LosAngeles, [this.checkCondition(this.state.direction === 'rtl', "marginLeft", "marginRight")]: "25px", [this.checkCondition(this.state.direction === 'rtl', "marginRight", "marginLeft")]: "auto"}} data-test-id="showMoreNew" onClick={(e) => { this.showMore(this.state.show === value.id ? '' : value.id, e) }}>{this.showMoreData(value.id)}</Box>
                                                        </MinBoxDisplayNew>
                                                    </Box>
                                                </Box>
                                                <Box style={{ display: this.showDataNone() }}>
                                                    <div className="table-container">
                                                        <table className="responsive-table">
                                                            <tbody>
                                                                <tr>
                                                                    <th>{this.getStringFinancialPlanner("Email")}:</th>
                                                                    <td style={webStyle.tdAnswers}>
                                                                        <Box style={webStyle.minBoxDisplay}>
                                                                            {this.showNameData(value)}
                                                                        </Box>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{this.getStringFinancialPlanner("Certifications")}:</th>
                                                                    <td>{value.attributes.certificate}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{this.getStringFinancialPlanner("Qualifications")}:</th>
                                                                    <td>{value.attributes.qualification}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{this.getStringFinancialPlanner("Firms")}:</th>
                                                                    <td>
                                                                        <div style={webStyle.emailLable}><span style={{ textTransform: 'lowercase' }} >{value.attributes.firm_website} &nbsp;</span><span style={{ marginTop: "4px" }}>
                                                                            <a href={value.attributes?.firm_website} target="_blank" data-test-id="stopPropagationNew" onClick={(event) => event.stopPropagation()} > <img src={openIcon} alt="" style={{ width: '17px', borderRadius: '0px' }} /></a></span></div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{this.getStringFinancialPlanner("EmployementHistory")}:</th>
                                                                    <td>{value.attributes.employment_history}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>{this.getStringFinancialPlanner("Disclosure")}:</th>
                                                                    <td>{value.attributes.disclosure}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <Divider style={webStyle.dividerLine} />
                                                    <Box style={webStyle.personalDetails}>
                                                        <Box style={webStyle.profileNameNew}>
                                                            <Box style={webStyle.focusLableNew}>{this.getStringFinancialPlanner("RatingsandReviews")}:</Box>
                                                            <Box className="isUpIcon">
                                                                {this.isUpIcon(value)}
                                                            </Box>
                                                        </Box>
                                                        <Box className="">
                                                            <Box className="profileNameNew" style={webStyle.profileNameNew}>
                                                                {this.average_rating_Data_Value(value)}
                                                            </Box>
                                                        </Box>

                                                        <Box style={webStyle.textlabel_NameOfCorporation}>{this.total_reviews_reviews(value)}</Box>
                                                        <Box style={{ display: this.isUpData() }}>
                                                            <Box className="reviews-container" style={webStyle.marginTopBox}>
                                                                {value.attributes.rating_and_reviews?.slice(0, 2).map((ratingData: any, index: number) => {
                                                                    return (
                                                                        <>
                                                                            <Box className="details" key={index} style={webStyle.displayBox} id="details">
                                                                                <Box className="profile-name" style={webStyle.profileName} id="profileName">

                                                                                    {this.renderProfile(ratingData.user_photo, ratingData.user_name, "50px", "24px")}

                                                                                    <Typography style={webStyle.reviewerName} className="reviewerName">{ratingData.user_name}</Typography>
                                                                                </Box>
                                                                                <Box id="dateRating" style={webStyle.profileName} className="date-rating">
                                                                                    <Typography style={webStyle.reviewerNameText} className="date-of-review" >{ratingData.date}</Typography>
                                                                                    <Box id="reviewStarFordetailsReviews" style={webStyle.fontSizeText}>
                                                                                        <Rating value={ratingData.rating} name="size-small" emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor} />} readOnly precision={0.5} size="small" />
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                            <Box>
                                                                                <Typography style={webStyle.consecteturText} className="consecteturText" data-test-id="main-review-content">{ratingData.description}</Typography>
                                                                            </Box>
                                                                        </>
                                                                    )
                                                                })}
                                                                {
                                                                    value.attributes.total_reviews > 2 &&
                                                                    <Box className="all-review-btn" data-test-id="dialogCashModelNew" onClick={(e) => { this.dialogCashModelNew(e, value.id, value.attributes.average_rating) }} style={webStyle.marginTopBox}>
                                                                        <span style={webStyle.allReviewBtn}>{this.getStringFinancialPlanner("AllReviews")}</span>
                                                                    </Box>
                                                                }

                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    })}
                                </Box>
                                <Box style={webStyle.minBoxDisplay1}>
                                    <NextButton data-test-id="sendEngagementButton" onClick={this.handleOpenCashModel}>{this.getStringFinancialPlanner("SendEngagementRequest")}</NextButton>
                                </Box>
                                <Box>
                                    <Dialog
                                        open={this.state.openCashModel}
                                        keepMounted
                                        data-test-id="dialogModel"
                                        onClose={this.handleCloseCashModel}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                        PaperProps={{ style: this.dialogCashPaperStyleNew }}
                                        style={{ textAlign: "center" }}
                                    >
                                        <DialogTitle id="alert-dialog-slide-title"><img src={modelSuccess} /></DialogTitle>
                                        <DialogContent style={webStyle.openModel}>
                                            <DialogContentText id="alert-dialog-slide-description" style={webStyle.modelRequest}>{this.messengDataSuccessfully()}</DialogContentText>
                                        </DialogContent>
                                    </Dialog>
                                </Box>

                            </Box>
                        </CnvestmentBox>
                        :
                        <CnvestmentBox>
                            <Box>
                                <Box style={webStyle.planning}>{this.getStringFinancialPlanner("EstatePlanning")}</Box>
                                <Box style={webStyle.listLine}>{this.getStringFinancialPlanner("selectPlannerDesc")}</Box>
                            </Box>
                            <Box>
                                {this.state.FinancialPlanner.data?.map((value: any, index: number) => {
                                    return (
                                        <Box key={index} style={this.clickedNone(value.id)} data-test-id="handleClickNew" onClick={() => this.handleClickNew(value.id)}>
                                            <Box style={webStyle.planningBackground}>
                                                <Box className="planner-content">
                                                    <Box style={{
                                                        display: 'flex',
                                                        gap: '10px'
                                                    }}>
                                                        {this.renderProfile(value.attributes.image, value.attributes.name, "74px")}
                                                        <Box style={webStyle.boxMargin}>
                                                            <TextLable>{value.attributes.name}</TextLable>
                                                            <Box style={webStyle.textlabel_NameOfCorporation}>{this.getStringFinancialPlanner("NameofCorporation")}:&nbsp;<Textlabel_LosAngeles>{value.attributes.firm}</Textlabel_LosAngeles></Box>
                                                            <Box style={webStyle.textlabel_NameOfCorporation}>{this.getStringFinancialPlanner("Location")}:&nbsp;<Textlabel_LosAngeles>{value.attributes.location}</Textlabel_LosAngeles></Box>
                                                            <Box style={webStyle.textlabel_NameOfCorporation}>{this.getStringFinancialPlanner("JurisdictionAdvise")}:&nbsp;<Textlabel_LosAngeles>{value.attributes.jurisdiction_advice}</Textlabel_LosAngeles></Box>
                                                        </Box>

                                                    </Box>

                                                    <Box style={webStyle.boxMargin}>
                                                        <Box style={{ ...webStyle.textlabel_NameOfCorporation, marginTop: "22px" }}>{this.getStringFinancialPlanner("Experience")}:&nbsp;<Textlabel_LosAngeles>{value.attributes.experience} {this.checkCondition(value.attributes.experience > 1, this.getStringFinancialPlanner("Yrs"), this.getStringFinancialPlanner("Yr"))}</Textlabel_LosAngeles></Box>
                                                        <Box className="" style={webStyle.profileNameNew}>
                                                            <Box style={{ ...webStyle.average_rating_fontSize, marginLeft: "auto" }} className="">{value.attributes.average_rating}</Box>&nbsp;&nbsp;
                                                            <Box style={{ ...webStyle.fontSizeText, marginLeft: "0" }} className="">
                                                                {this.average_rating_rating(value)}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Box style={webStyle.focusLable}>{this.getStringFinancialPlanner("Focus")}:</Box>
                                                    <MinBoxDisplayNew >
                                                        {value.attributes.focus.map((valueButton: any, index: number) => { return <ButtonStyle key={index}>{valueButton.name}</ButtonStyle> })}
                                                        <Box style={{ ...webStyle.textlabel_LosAngeles, [this.checkCondition(this.state.direction === 'rtl', "marginLeft", "marginRight")]: "25px", [this.checkCondition(this.state.direction === 'rtl', "marginRight", "marginLeft")]: "auto" }} data-test-id="showMore" onClick={(e) => { this.showMore(this.state.show === value.id ? '' : value.id, e) }}>{this.showMoreData(value.id)}</Box>
                                                    </MinBoxDisplayNew>
                                                </Box>
                                            </Box>
                                            <Box style={{ display: this.showNoneData(value.id) }}>
                                                <div className="table-container">
                                                    <table className="responsive-table">
                                                        <tbody>
                                                            <tr>
                                                                <th>{this.getStringFinancialPlanner("Email")}:</th>
                                                                <td style={webStyle.tdAnswers}>
                                                                    <Box style={webStyle.minBoxDisplay}>
                                                                        {this.visibilityOutlinedIcon(value)}
                                                                    </Box>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className="new-Certificationss">{this.getStringFinancialPlanner("Certifications")}:</th>
                                                                <td>{value.attributes.certificate}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="new-Qualificationss">{this.getStringFinancialPlanner("Qualifications")}:</th>
                                                                <td>{value.attributes.qualification}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="new-Firmss">{this.getStringFinancialPlanner("Firms")}:</th>
                                                                <td>
                                                                    <div style={webStyle.emailLable}><span style={{ textTransform: 'lowercase' }}>{value.attributes.firm_website} &nbsp;</span><span style={{ marginTop: "4px" }}>
                                                                        <a href={value.attributes.firm_website} target="_blank" data-test-id="stopPropagation" onClick={(e) => e.stopPropagation()} > <img src={openIcon} alt="" style={{ width: '17px', borderRadius: '0px' }} /></a></span></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className="new-EmployementHistorys">{this.getStringFinancialPlanner("EmployementHistory")}:</th>
                                                                <td>{value.attributes.employment_history}</td>
                                                            </tr>
                                                            <tr>
                                                                <th className="new-Disclosures">{this.getStringFinancialPlanner("Disclosure")}:</th>
                                                                <td>{value.attributes.disclosure}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <Divider style={webStyle.dividerLine} />
                                                <Box className="personalDetails" style={webStyle.personalDetails}>
                                                    <Box style={webStyle.profileNameNew}>
                                                        <Box style={webStyle.focusLableNew} className="focusLableNew">{this.getStringFinancialPlanner("RatingsandReviews")}:</Box>
                                                        <Box>
                                                            {this.isUpIcon(value)}
                                                        </Box>
                                                    </Box>
                                                    <Box className="">
                                                        <Box className="" style={webStyle.profileNameNew}>
                                                            {this.average_rating_Data_Value(value)}
                                                        </Box>
                                                    </Box>
                                                    <Box style={webStyle.textlabel_NameOfCorporation}>{this.total_reviews_reviews(value)}</Box>
                                                    <Box style={{ display: this.isUpData() }}>
                                                        <Box className="reviews-container" style={webStyle.marginTopBox}>
                                                            <Box className="reviews" >
                                                                {value.attributes.rating_and_reviews?.slice(0, 2).map((ratingData: any, index: number) => {
                                                                    return (
                                                                        <>
                                                                            <Box key={index} className="details" style={webStyle.displayBox} id="details">
                                                                                <Box className="profile-name" style={webStyle.profileName} id="profileName">

                                                                                    {this.renderProfile(ratingData.user_photo, ratingData.user_name, "50px", "24px")}

                                                                                    <Typography style={webStyle.reviewerName} className="reviewerName">{ratingData.user_name}</Typography>
                                                                                </Box>
                                                                                <Box id="dateRating" className="date-rating" style={webStyle.profileName}>
                                                                                    <Typography className="date-of-review" style={webStyle.reviewerNameText}>{ratingData.date}</Typography>
                                                                                    <Box id="reviewStarFordetailsReviews" style={webStyle.fontSizeText}>
                                                                                        <Rating name="size-small" value={ratingData.rating} emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor} />} readOnly precision={0.5} size="small" />
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                            <Box>
                                                                                <Typography data-test-id="main-review-content" style={webStyle.consecteturText}>{ratingData.description}</Typography>
                                                                            </Box>
                                                                        </>
                                                                    )
                                                                })}
                                                            </Box>
                                                            {
                                                                value.attributes.total_reviews > 2 &&
                                                                <Box className="all-review-btn" data-test-id="dialogCashModel" onClick={(e) => { this.dialogCashModelNew(e, value.id, value.attributes.average_rating) }} style={webStyle.marginTopBox}>
                                                                    <span style={webStyle.allReviewBtn}>{this.getStringFinancialPlanner("AllReviews")}</span>
                                                                </Box>
                                                            }

                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Box>
                            <Box style={webStyle.minBoxDisplay1}>
                                {this.isClickedIcon()}
                            </Box>
                        </CnvestmentBox>}
                </BackgroundBoxSatyle >
                <Box>
                    <Dialog
                        open={this.state.isPopupOpenNew}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={this.handleDialogClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogCashPaperStyleNew }}
                    >
                        <DialogContent style={webStyle.openModel} dir={this.state.direction}>
                            <Box className="">
                                <Box className="" style={webStyle.profileNameNew}>
                                    <Box style={webStyle.average_rating_fontSize} className="">{this.state.avgRating}</Box>&nbsp;&nbsp;
                                    <Box style={webStyle.fontSizeText} className="">
                                        {this.state.avgRating && <Rating name="size-medium" size="small" value={this.state.avgRating} emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor} />} readOnly precision={0.5} />}
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={webStyle.textlabel_NameOfCorporation}>{this.getStringFinancialPlanner("basedoutof")} {this.state.rating_and_reviews_Model.length} {this.getStringFinancialPlanner("reviews")}</Box>
                            <Box className="" style={webStyle.marginTopBox}>
                                {this.state.rating_and_reviews_Model.map((ratingDataModel, index: number) => {
                                    return (
                                        <>
                                            <Box key={index} className="details" style={webStyle.displayBox} id="details">
                                                <Box className="profile-name" style={webStyle.profileName} id="profileName">
                                                    {this.renderProfile(ratingDataModel.attributes.user_photo, ratingDataModel.attributes.user_name, "50px", "24px")}
                                                    <Typography style={webStyle.reviewerName} className="reviewerName">{ratingDataModel.attributes.user_name}</Typography>
                                                </Box>
                                                <Box id="dateRating" className="date-rating" style={webStyle.profileName}>
                                                    <Typography className="date-of-review" style={webStyle.reviewerNameText}>{ratingDataModel.attributes.date}</Typography>
                                                    <Box id="reviewStarFordetailsReviews" style={webStyle.fontSizeText}>
                                                        <Rating name="size-small" emptyIcon={<StarIcon fontSize="inherit" style={webStyle.stareColor} />} value={ratingDataModel.attributes.rating} readOnly precision={0.5} size="small" />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography data-test-id="main-review-content" style={webStyle.consecteturText}>{ratingDataModel.attributes.description}</Typography>
                                            </Box>
                                        </>
                                    )
                                })}
                            </Box>
                        </DialogContent>
                    </Dialog>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties }

const webStyle: IStyle = {
    handleClickMargin: { margin: '0px 15px' },
    dividerLine: { color: '#607d8b' },
    openModel: { padding: '8px 24px' },
    average_rating_fontSize: { color: '#fff', fontSize: '12px' },
    expandMoreRounded: { color: '#3cd7c6' },
    stareColor: { color: '#ceccc2' },
    modelRequest: { fontSize: "20px", color: "white", fontFamily: "Poppins" },
    marginImg: { margin: '0px 10px', cursor: "pointer" },
    minBoxDisplay1: { display: 'flex', justifyContent: 'center' },
    marginTopBox: { marginTop: '20px' },
    consecteturText: { color: '#fff', fontSize: '14px', fontWeight: 400, margin: '20px 0px' },
    fontSizeText: { fontSize: '12px', color: 'white' },
    displayBox: { display: 'flex', alignItems: 'center', justifyContent: 'space-between'},
    clicked: { boxShadow: "#1bf5e3 0px 0px 10px", border: "1px solid #1bf5e3", borderRadius: "15px", background: "#395d6b", marginBottom: '30px', width: '103%' },
    emailLable: { display: 'flex' },
    tdAnswers: { color: '#2CE2D5', fontSize: '14px', fontWeight: 600, paddingLeft: '15px' },
    tdQuestions: { color: '#fff', fontSize: '14px', fontWeight: 400, marginBottom: "6px" },
    personalDetails: { padding: '10px' },
    boxMarginEnd: { margin: '0px 25px', display: "flex", justifyContent: "end", width: "100%", marginTop: "-2rem" },
    minBoxDisplay: { display: 'flex', justifyContent: 'space-between', flexWrap: "wrap" },
    planning: { fontSize: "25px", fontWeight: 600, color: "white" },
    listLine: { color: "#94929f", margin: "25px 0px" },
    planningBackground: { borderRadius: "12px", border: "1px solid #57575D", background: "#3C3C51", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", padding: "10px 20px 25px 25px", },
    webStyleMenBox: { borderRadius: "12px", border: "1px solid #57575D", background: "#395d6b", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", marginBottom: '30px', width: '100%' },
    textlabel_NameOfCorporation: { color: "#928F98", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 400, display: "flex" },
    textlabel_LosAngeles: { color: "#2DE2D5", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 500, cursor: "pointer" },
    focusLable: { color: "#FFF", fontFamily: "Poppins", fontSize: "16px", fontWeight: 600, margin: "10px 0px" },
    focusLableNew: { color: "#FFF", fontFamily: "Poppins", fontSize: "16px", fontWeight: 600 },
    allReviewBtn: { borderRadius: '6px', border: '1px solid #C9F9F6', padding: '5px 20px', color: "#C9F9F6", cursor: 'pointer' },
    reviewerName: { color: '#fff', fontWeight: 400 },
    reviewerNameText: { color: '#fff', opacity: '0.5', fontWeight: 500 },
    profileName: { display: 'flex', alignItems: 'center', gap: '10px' },
    reviewerDP: { borderRadius: '50px', width: '50px', height: '50px' },
    profileNameNew: { display: 'flex', alignItems: 'center' },
    noImg: {
        height: "94px",
        display: "flex",
        borderRadius: "50%",
        width: "94px",
        color: "#fff",
        fontWeight: 600,
        backgroundColor: "2CE2D5",
        fontSize: "32px",
        alignItems: "center",
        justifyContent: 'center',
        textTransform: "uppercase"
    },
};

const BackgroundBoxSatyle = styled(Box)(({ dir }) => ({
    width: '100%',
    height: '100vh',
    overflowY: 'scroll',
    backgroundColor: '#2b2a37',
    "& .responsive-table td": {
      textAlign: dir === 'rtl' ? 'right' : 'left',
    },
    "& .responsive-table th": {
        textAlign: dir === 'rtl' ? 'right' : 'left',
    },
    "& .MuiRating-root": {
        textAlign: dir === 'rtl' ? 'right' : 'left',
    }
  }));
const CnvestmentBox = styled(Box)({
    paddingLeft: "80px !important", paddingRight: "80px !important", paddingTop: "40px !important",
    '@media (min-width: 320px)': { paddingLeft: "15px !important", paddingRight: "15px !important" },
    '@media (min-width: 1200px)': { paddingLeft: "80px !important", paddingRight: "80px !important" },
})

const ButtonStyle = styled(Button)({
    borderRadius: "15px", background: "#395D6B", color: "#FFF", textAlign: "center", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 400, padding: "8px 20px", textTransform: "capitalize", margin: '10px 20px 10px 0px', '&:hover': {
        backgroundColor: "#395D6B",
    },
})
const TextLable = styled(Box)({ color: "#FFF", fontFamily: "Poppins", fontSize: "20px", fontStyle: "normal", fontWeight: 600, textTransform: "capitalize" })
const Textlabel_LosAngeles = styled(Box)({ color: "#2DE2D5", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 500, cursor: "pointer", textTransform: "capitalize" })
const StdQuestions1 = styled(Box)({
    color: '#fff', fontSize: '14px', fontWeight: 400, paddingLeft: '15px', textTransform: "capitalize"

})
const NextButton = styled(Button)({
    background: "#ccfbf6", marginBottom: "20px", textTransform: "capitalize", fontWeight: 600, fontFamily: "Poppins", padding: "12px",
    fontSize: "16px",

    '&:hover': { background: "#ccfbf6", marginBottom: "10px", textTransform: "capitalize", fontWeight: 600 },
    '@media (min-width: 320px)': { width: "100%" },
    '@media (min-width: 1200px)': { width: "30%", },

})
const NextButtonNew = styled(Button)({
    background: "#7b9398", marginBottom: "20px", textTransform: "capitalize", fontWeight: 600, fontFamily: "Poppins", color: "#292931", cursor: "none",
    padding: "12px", fontSize: "16px",
    '&:hover': { background: "#7b9398", marginBottom: "10px", textTransform: "capitalize", fontWeight: 600 },
    '@media (min-width: 320px)': { width: "100%" },
    '@media (min-width: 1200px)': { width: "30%", },
})
const MinBoxDisplayNew = styled(Box)({
    display: 'flex', flexWrap: "wrap", alignItems: "flex-end"
})
// Customizable Area End