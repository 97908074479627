import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  createTheme,
  ThemeProvider,
  Typography,
  styled,
  Popover,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomPagination from "./CustomPagination";
import {
  morevert,
  selectedStar,
  unselectedStar,
} from "../../blocks/dashboard/src/assets";
import Rating from "@material-ui/lab/Rating";
import {
  IClientCompletedService,
  IClientInProgressService,
  IReview,
} from "../../blocks/dashboard/src/DashboardController";
import { formatNumberWithCommas } from "./commonFn";

interface IClient {
  id: string;
  type: string;
  attributes: {
    client_id: number;
    name: string;
    date: string;
    request_status: string;
    review: IReview;
    status: string;
    proposal_sent_status: boolean;
  };
}
const ClientList: React.FC<{
  totalRecord: number;
  rowPerPage: number;
  handlePageChange: (page: number) => void;
  clients: IClient[];
  clientServices: IClientInProgressService[] | IClientCompletedService[];
  page: number;
  activeTab: string;
  goToClientPage: (clientId:number) => void
}> = ({
  totalRecord,
  rowPerPage,
  handlePageChange,
  clients,
  page,
  activeTab,
  clientServices,
  goToClientPage
}) => {
  let theme = createTheme({
    palette: {
      primary: {
        main: "#2CE2D5",
        contrastText: "#fff",
      },
    },
  });
  theme = createTheme(theme, {
    palette: {
      primary: {
        main: "#2CE2D5",
        contrastText: "#fff",
      },
    },
    overrides: {
      MuiTableContainer: {
        root: {
          boxShadow: "none",
          borderRadius: 0,
          "&.normal-grid": {
            "& .MuiTable-root": {
              "& .MuiTableHead-root": {
                "& .MuiTableCell-root": {
                  padding: "22px 22px",
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: "20px",
                  letterSpacing: "0.25px",
                  color: "#8f99a3",
                  borderColor: "#808d9e",
                  whiteSpace: "nowrap",
                },
              },
              "& .MuiTableBody-root": {
                "& .MuiTableRow-root.MuiTableRow-hover:hover": {
                  backgroundColor: "rgba(9,72,73,0.05)",
                  cursor: "pointer",
                  paddingTop: 6,
                  paddingBottom: 6,
                },
                "& .MuiTableCell-root": {
                  padding: "22px 22px",
                  fontFamily: "'MaderaRegular', sans-serif",
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: "20px",
                  letterSpacing: "0.25px",
                  color: "#fff",
                  borderColor: "#808d9e",
                },
              },
            },
          },
        },
      },

      MuiPopover: {
        root: {
          zIndex: 999,
          borderRadius: "14px",
          "&.simple-tooltip .MuiPaper-root": {
            padding: 14,
            boxShadow: "none",
            fontSize: 12,
            letterSpacing: "0.4px",
            backgroundColor: "#395d6b !important",
            borderRadius: "14px",
          },
          "& .MuiPaper-rounded": {
            borderRadius: "0px",
            boxShadow: "none",
          },
        },
      },
    },
  });

  const [myClients, setMyClients] = useState<IClient[]>(clients);
  const [inProgressServices, setInProgressServices] = useState<
    IClientInProgressService[]
  >([]);
  const [completedServices, setCompletedServices] = useState<
  IClientCompletedService[]
>([]);
  const [clientId,setClientId] = useState<number>(0)


  const [currentReview, setCurrentReview] = useState<IReview>();

  useEffect(() => {
        setMyClients(clients);
    let inProgress :IClientInProgressService []= [];
    let completed :IClientCompletedService []= [];
    clientServices.forEach((service:IClientInProgressService | IClientCompletedService)  => {
      if ('service' in service) {
        inProgress.push(service);
      } else {
        completed.push(service);
      }
  });

    setInProgressServices(inProgress);
    setCompletedServices(completed);

  }, [clients, clientServices]);

  const [
    ratingToolTipEl,
    setRatingToolTipEl,
  ] = useState<HTMLSpanElement | null>(null);
  const [
    goToClientTooltip,
    setGoToClientTooltip,
  ] = useState<HTMLSpanElement | null>(null);

  const renderRequestedStatus = (status: string) => {
    switch (status) {
      case "accepted":
        return (
          <Typography
            style={{
              ...webstyles.requestStatusStyle,
              background: "#DAF8DA",
              color: "#0ABE64",
            }}
          >
            Accepted
          </Typography>
        );
      case "pending":
        return (
          <Typography
            style={{
              ...webstyles.requestStatusStyle,
              background: "#EEECCE",
              color: "#EBAA1A",
            }}
          >
            Pending
          </Typography>
        );
      case "rejected":
        return (
          <Typography
            style={{
              ...webstyles.requestStatusStyle,
              background: "#F7C1BA",
              color: "#EE5353",
            }}
          >
            Rejected
          </Typography>
        );
    }
  };
  const renderStatus = (status: string) => {
    switch (status) {
      case "Services sent":
        return (
          <Typography
            style={{
              ...webstyles.statusStyle,
              background: "#DAF8DA",
              color: "#0ABE64",
            }}
          >
            {status}
          </Typography>
        );
      case "Call Requested":
        return (
          <Typography
            style={{
              ...webstyles.statusStyle,
              background: "#EDDEF6",
              color: "#814FF9",
            }}
          >
            {status}
          </Typography>
        );
      case "Pending Services":
        return (
          <Typography
            style={{
              ...webstyles.statusStyle,
              background: "#AECEF1",
              color: "#1277DF",
            }}
          >
            {status}
          </Typography>
        );
      case "Awaiting Response":
        return (
          <Typography
            style={{
              ...webstyles.statusStyle,
              background: "#EEECCE",
              color: "#EBAA1A",
            }}
          >
            {status}
          </Typography>
        );
      case "Rejected Request":
        return (
          <Typography
            style={{
              ...webstyles.statusStyle,
              background: "#F7C1BA",
              color: "#EE5353",
            }}
          >
            {status}
          </Typography>
        );
      default:
        return (
          <Typography
            style={{
              ...webstyles.statusStyle,
              background: "#DAF8DA",
              color: "#0ABE64",
            }}
          >
            Status not added
          </Typography>
        );
    }
  };
  const renderTransactionStatus =(status:string)=>{
    switch(status){
     case "completed":
      return (
        <Typography
          style={{
            ...webstyles.requestStatusStyle,
            background: "#DAF8DA",
            color: "#0ABE64",
          }}
        >
          Successfull
        </Typography>
      );
      case "pending":
        return (
          <Typography
            style={{
              ...webstyles.requestStatusStyle,
              background: "#EEECCE",
              color: "#EBAA1A",
            }}
          >
            Pending
          </Typography>
        );
        case "rejected":
          return (
            <Typography
              style={{
                ...webstyles.requestStatusStyle,
                background: "#F7C1BA",
                color: "#EE5353",
              }}
            >
            {status}
            </Typography>
          );
          default:
            return (
              <Typography
                style={{
                  ...webstyles.statusStyle,
                  background: "#DAF8DA",
                  color: "#0ABE64",
                }}
              >
                Status not added
              </Typography>
            );
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <TableContainer className="normal-grid">
        <Table style={{ minWidth: 750 }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Client Name</TableCell>
              {activeTab !== "pending"&&activeTab !== "payment" && (
                <TableCell align="left">Service</TableCell>
              )}
               {activeTab === "payment" && (
                <TableCell align="left">Amount</TableCell>
              )}
              
              <TableCell align="left">Date</TableCell>
              {activeTab === "payment" && (
                <>
                  <TableCell align="left">Status</TableCell>
                </>
              )}
              {activeTab !== "payment" &&activeTab!=='in_progress'&&activeTab!=='completed'&& (
                <>
                  <TableCell align="left">Request Status</TableCell>
                  <TableCell align="left">Status</TableCell>
                </>
              )}
              {activeTab !== "in_progress" &&activeTab !== "payment" && (
                <TableCell align="left">Review</TableCell>
              )}
              {activeTab === "in_progress"  && (
                <TableCell align="left">Status</TableCell>
              )}
             
            </TableRow>
          </TableHead>
          <TableBody>
            {activeTab === "pending" &&
              myClients.map((client) => (
                <TableRow key={client.id}>
                  <TableCell align="left" style={webstyles.capitalize}>
                  <div
                      style={{
                        cursor: "pointer",
                        maxWidth:'100px', 
                        wordWrap:"break-word"
                      }}
                      onClick={() => {
                        setClientId(client.attributes.client_id)
                        goToClientPage(client.attributes.client_id)
                      }}
                    >
                    {client.attributes.name
                      ? client.attributes.name
                      : "Client Name"}
                      </div>
                  </TableCell>
                  <TableCell align="left">{client.attributes.date}</TableCell>
                  <TableCell align="left">
                    {renderRequestedStatus(client.attributes.request_status)}
                  </TableCell>
                  <TableCell align="left">
                    {renderStatus(client.attributes.status)}{" "}
                  </TableCell>
                  <TableCell align="left">
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={(event) => {
                        if (client.attributes.review) {
                          setCurrentReview(client.attributes.review);
                          setRatingToolTipEl(event.currentTarget);
                        }
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#fff",
                          marginRight: "4px",
                        }}
                        component={"span"}
                      >
                        {client.attributes.review
                          ? client.attributes.review.average.toFixed(1)
                          : ""}
                      </Typography>

                      <StyledRating
                        name="simple-controlled"
                        value={
                          client.attributes.review
                            ? client.attributes.review.average
                            : 0
                        }
                        readOnly
                        icon={
                          <div>
                            <img src={selectedStar}   />
                          </div>
                        }
                        emptyIcon={
                          <div>
                            <img src={unselectedStar}  />
                          </div>
                        }                  
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
  {activeTab === "payment" &&
              myClients.map((client:any) => (
                <TableRow key={client.id}>
                  <TableCell align="left" style={{maxWidth:'100px', wordWrap:"break-word"}}>
                    {client.name
                      ? client.name
                      : "Client Name"}
                  </TableCell>
                  <TableCell align="left">{formatNumberWithCommas(client.amount??'120')} AED</TableCell>

                  <TableCell align="left">{client.date}</TableCell>
                  <TableCell align="left">
                    {renderTransactionStatus(client.status)}{" "}
                  </TableCell>
                  
                </TableRow>
              ))}
            {activeTab === "completed" &&
              completedServices.map(
                (service , index) => {
                  return  (
                    <TableRow key={index}>
                      <TableCell align="left">
                      <div
                      style={{
                        cursor: "pointer",
                        maxWidth:"100px",
                        wordWrap:'break-word'
                      }}
                      onClick={() => {
                        setClientId(service.attributes.client_id)
                        goToClientPage(service.attributes.client_id)
                      }}
                    >
                        {service.attributes.name
                          ? service.attributes.name
                          : "Client Name"}
                          </div>
                      </TableCell>

                      <TableCell align="left">
                        {" "}
                        {service.attributes.step_description}{" "}
                      </TableCell>
                      <TableCell align="left">
                        {service.attributes.step_completion_date}
                      </TableCell>
                      {activeTab === "completed" && (
                        <TableCell align="left">
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(event) => {
                              if (service.attributes.review) {
                                setCurrentReview(service.attributes.review);
                                setRatingToolTipEl(event.currentTarget);
                              }
                            }}
                          >
                            <Typography
                              component={"span"}
                              style={{
                                fontSize: "12px",
                                color: "#fff",
                                marginRight: "4px",
                              }}
                            >
                              {service.attributes.review
                                ? service.attributes.review.average.toFixed(1)
                                : ""}
                            </Typography>

                            <StyledRating
                              name="simple-controlled"
                              value={
                                service.attributes.review
                                  ? service.attributes.review.average
                                  : 0
                              }
                              readOnly
                              icon={
                                <div>
                                  <img src={selectedStar} />
                                </div>
                              }
                              emptyIcon={
                                <div>
                                  <img src={unselectedStar} />
                                </div>
                              }
                            />
                          </div>{" "}
                        </TableCell>
                      )}
                    </TableRow>
                  ) 
                }
              )}
            {activeTab === "in_progress" &&
              inProgressServices.map( (service, index) => {
                return  (
                  <TableRow key={index}>
                    <TableCell align="left">
                    <div
                      style={{
                        cursor: "pointer",
                        maxWidth:'100px',
                        wordWrap:'break-word'
                      }}
                      onClick={(event) => {
                        setClientId(service.client_id)
                        goToClientPage(service.client_id)
                      }}
                    >
                      {service.name ? service.name : "Client Name"}
                      </div>
                    </TableCell>

                    <TableCell align="left"> {service.service} </TableCell>
                    <TableCell align="left">{service.date}</TableCell>

                    <TableCell align="left">
                      <Typography
                        style={{
                          ...webstyles.statusStyle,
                          background: "#DAF8DA",
                          color: "#0ABE64",
                        }}
                      >
                        {service.status}
                      </Typography>
                    </TableCell>

                  </TableRow>
                ) 
              })}
              {
                totalRecord === 0 &&
                 <TableRow >
                  <TableCell align="center" colSpan={activeTab === "pending"? 6: 5}>
                    No Record Found!
                  </TableCell>
                  </TableRow>
                
              }
          </TableBody>
        </Table>
      </TableContainer>
      {(clients.length > 0 || clientServices.length > 0) && (
        <Box
          style={{
            marginTop: "2rem",
          }}
        >
          <CustomPagination
            totalRecord={totalRecord}
            rowPerPage={rowPerPage}
            handlePageChange={handlePageChange}
            page={page}
          />
        </Box>
      )}

      <Popover
        open={Boolean(ratingToolTipEl)}
        className="simple-tooltip"
        elevation={0}
        disableScrollLock={true}
        anchorEl={ratingToolTipEl}
        onClose={() => setRatingToolTipEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            marginTop: "10px",
          },
        }}
      >
        <Box
          style={{
            maxHeight: "250px",
            width: "256px",
          }}
        >
          {currentReview &&
            currentReview.reviews.map((item) => {
              return (
                <Box
                  key={item.id}
                  style={{
                    marginBottom: "12px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        opacity: 0.7,
                        fontSize: "14px",
                        color: "#fff",
                      }}
                    >
                      {item.date}
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          opacity: 0.7,
                          fontSize: "14px",
                          color: "#fff",
                          marginRight: "4px",
                        }}
                      >
                        {item.rating}{" "}
                      </Typography>
                      <StyledRating
                        name="simple-controlled"
                        value={item.rating}
                        readOnly
                        icon={
                          <div>
                            <img src={selectedStar} />
                          </div>
                        }
                        emptyIcon={
                          <div>
                            <img src={unselectedStar} />
                          </div>
                        }
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        opacity: 0.7,
                        fontSize: "14px",
                        color: "#fff",
                        wordBreak: "break-all",
                        marginTop: "12px",
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Popover>
      <Popover
        open={Boolean(goToClientTooltip)}
        className="simple-tooltip"
        elevation={0}
        anchorEl={goToClientTooltip}
        onClose={() => setGoToClientTooltip(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            marginTop: "10px",
          },
        }}
      >
        <Box onClick={()=> goToClientPage(clientId)}>
          <Typography
            style={{
              fontSize: "14px",
              color: "#fff",
            }}
          >
            Access Client Profile
          </Typography>
        </Box>
      </Popover>
    </ThemeProvider>
  );
};
type IStyle = { [key: string]: React.CSSProperties };

const StyledRating = styled(Rating)({
  "& .MuiRating-label": {
    paddingLeft: "6px",
  },
  "& span": {
    padding: "1px",
  },
  "& img": {
    height: "12px",
    width: "11px",
    maxWidth:"11px"
  },
});

const webstyles: IStyle = {
  requestStatusStyle: {
    borderRadius: "10px",
    textAlign: "center",
    fontSize: "12px",
    padding: "6px 16px 6px 16px",
    fontFamily: "Poppins",
    width: "100px",
  },
  statusStyle: {
    borderRadius: "10px",
    textAlign: "center",
    fontSize: "12px",
    padding: "6px 16px 6px 16px",
    fontFamily: "Poppins",
    width: "160px",
  },
  serviceStatusStyle: {
    borderRadius: "10px",
    textAlign: "center",
    fontSize: "12px",
    padding: "6px 16px 6px 16px",
    fontFamily: "Poppins",
    width: "160px",
    background: "#DAF8DA",
    color: "#0ABE64",
  },
  capitalize: {
    textTransform: "capitalize"
  }
};
export default ClientList;
