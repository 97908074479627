// Customizable Area Start
import React from "react";

import { Box, Grid, Menu, MenuItem, ThemeProvider, Typography, createTheme, Button } from "@material-ui/core";
import { PlusIcon,openIcon, threeDots } from "./assets"
import FormApprovalWorkflowController, {
  Props, IPlannedEvent
} from "./FormApprovalWorkflowController.web";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { modelSuccess } from '../../multipageforms2/src/assets';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';
import { getFirstCharacterFromEachWord, convertISOTo12HourFormat } from "../../../components/src/commonFn";
import { styled } from '@material-ui/styles';
import './planner.css';


let theme = createTheme({
  palette: {
    primary: {
      main: "#2CE2D5",
      contrastText: "#fff",
    }
  },
  typography: {
    fontFamily: 'Poppins !important'
  },
});

export default class FormApprovalWorkflow extends FormApprovalWorkflowController {
  constructor(props: Props) {
    super(props);
  }

  showMoreData = (value: string) => this.state.show === value ? this.getStringTransactions('Less'): this.getStringTransactions('More');

  showNoneData = (value: string) => this.state.show === value ? '' : 'none';




  isUpIcon = (value: IPlannedEvent) => {
    return value.attributes.total_reviews ?
      <IconButton data-test-id="handleClickNewData" onClick={(event) => { this.handleClick(event) }}>
        {this.state.isUp ? <ExpandLessRoundedIcon style={FormApprovalWorkflowStyles.expandMoreRounded} /> : <ExpandMoreRoundedIcon style={FormApprovalWorkflowStyles.expandMoreRounded} />}
      </IconButton> :
      null
  }
  getLabelDesign = (status: string) => {

    if (status === "pending") {
      return FormApprovalWorkflowStyles.labelDesignForPending
    }

    if (status === "accepted") {
      return FormApprovalWorkflowStyles.labelDesignForAccepted
    }

    return FormApprovalWorkflowStyles.labelDesignForRejected
  }


  showNameData = (value: IPlannedEvent) => {
    return (
      <React.Fragment>
        {this.state.showName && value.id === this.state.selectedPlannedEvent?.id ? value.attributes.email : this.maskEmail(value.attributes.email)}&nbsp;&nbsp;&nbsp;&nbsp;
        {this.state.showName && value.id === this.state.selectedPlannedEvent?.id ? (
          < VisibilityOutlinedIcon style={FormApprovalWorkflowStyles.handleClickMargin} data-test-id="visibility-icon" onClick={(event) => this.handleIconClick(event, value)} />
        ) : (
          <VisibilityOffOutlinedIcon style={FormApprovalWorkflowStyles.handleClickMargin} data-test-id="visibility-off-icon" onClick={(event) => this.handleIconClick(event, value)} />
        )}
      </React.Fragment>
    )
  }

  isUpData = () => this.state.isUp === false ? "" : "none";

  average_rating_display = (value: IPlannedEvent) => {
    return value.attributes.average_rating ?
      <>
        <Typography style={FormApprovalWorkflowStyles.average_rating_fontSize} className="">{value.attributes.average_rating}</Typography>&nbsp;&nbsp;
        <Box style={FormApprovalWorkflowStyles.fontSizeText}>
          <Rating dir={'ltr'} style={{transform:this.state.direction === 'rtl' ?  'scaleX(-1)':''}} name="size-small" emptyIcon={<StarIcon fontSize="inherit" style={FormApprovalWorkflowStyles.stareColor} />} value={value.attributes.average_rating} readOnly precision={0.5} size="small" />
        </Box>
      </> :
      null
  }

  checkReviewEligibility = (status: string, eventStatus: boolean) => status === "completed" && !eventStatus;

  marginCss = (status: string) => status === "requested" ? "12px" : "0px";

  profileImage = (image: string, userName: string) => {
    return (<>
      {image ? <img src={image} width="80px" height="80px" style={{borderRadius:'16px', objectFit:'cover'}} data-test-id="pendingCardImage"/>
        :
        <div style={FormApprovalWorkflowStyles.noImg}>
          {getFirstCharacterFromEachWord(userName)}
        </div>}
    </>
    )
  }


  renderSmallProfile = (userImg: string, userName: string) => {
    return userImg ?
      <img src={userImg} alt="" style={FormApprovalWorkflowStyles.reviewerDP} className="reviewerDP" />
      :
      <div style={{ ...FormApprovalWorkflowStyles.noImg, height: "50px", width: '50px', fontSize: '24px' }}>
        {getFirstCharacterFromEachWord(userName)}
      </div>
  }


  renderModifyBookingBtn = (plannedEvent: IPlannedEvent) => {
    if (plannedEvent.attributes.request_status == 'rejected') {
      return <></>
    } else {
      return this.checkTime(plannedEvent) &&
        <Box style={{ marginTop: "30px", width: "77%" }} id="ongoing-btns-notification">
          <Typography style={FormApprovalWorkflowStyles.timeMsg} id="ongoing-btns-msg">{this.getStringTransactions('YourCallScheduled')} {plannedEvent.attributes.slot} {convertISOTo12HourFormat(plannedEvent.attributes.slot_time)}.</Typography>
          <Box
            style={{ ...FormApprovalWorkflowStyles.chatBtn, maxWidth: "380px", textAlign: 'center' }} onClick={() => this.goToModifyCall(plannedEvent)} data-test-id="modfiy-bookingBtn">{this.getStringTransactions('modifyTitle')}</Box>

        </Box>
    }
  }
  renderScheduleButton = (plannedEvent: IPlannedEvent) => {
    const isRejected = this.checkRejectedStatus(plannedEvent.attributes.request_status);
    const shouldScheduleCall = this.checkCall(plannedEvent);
    const buttonText = this.getButtonText(this.getResult(plannedEvent));
    if (isRejected) {
      return (
        <button
          style={{ ...FormApprovalWorkflowStyles.ScheduleCallBtn, cursor: "not-allowed", opacity: 0.6 }}
        >
          {this.getStringTransactions('ScheduleCall')}
        </button>
      );
    }

    return shouldScheduleCall ? (
      <button
        style={{ ...FormApprovalWorkflowStyles.ScheduleCallBtn }}
        onClick={() => { this.goToScheduleCall(plannedEvent.attributes.planner_id, plannedEvent.attributes.message) }}
        data-test-id="schedule-booking"
      >
        {this.getStringTransactions('ScheduleCall')}
      </button>
    ) : (
      <span
        style={FormApprovalWorkflowStyles.ScheduleCallBtn}
        id="ongoing-btns-call"
        data-test-id="gotovideoId"
        onClick={() => { this.gotoVideo(plannedEvent) }}
      >
        {buttonText}
      </span>
    );
  }
  getCurrentStatus = (status: string) => {
    if (status === "pending") {
      return this.getStringTransactions('Pending')
    }

    if (status === "accepted") {
      return this.getStringTransactions('Accepted')
    }

    return this.getStringTransactions('Rejected')

  }
  renderPlannedEvent = (plannedEvent: IPlannedEvent) => {
    return (
      <Grid container style={{
        marginTop: "20px",
        marginBottom: "20px"
      }}>

        {
          plannedEvent.attributes.new_services_added &&
          <Grid item md={11} style={{
            marginBottom: this.marginCss(plannedEvent.attributes.service_completed_status)
          }}>
            <Box style={FormApprovalWorkflowStyles.servicePopUpMsg}>
              <span style={FormApprovalWorkflowStyles.serviceAddedMsg}>{this.getStringTransactions('YourServiceAdded')}</span>
              <span style={FormApprovalWorkflowStyles.viewServiceBtn} data-test-id="view-service" onClick={() => {
                this.goToServices(plannedEvent.attributes.planner_id)
              }}> {this.getStringTransactions('ViewServices')}</span>
            </Box>
          </Grid>
        }
        <Grid item md={11} xs={11}>
          <Box style={FormApprovalWorkflowStyles.webStyleMainBox} >
            <Box style={FormApprovalWorkflowStyles.mainBtnWrapperForRequested} >
              {
                plannedEvent.attributes.service_completed_status === "requested" && <span
                  style={{
                    ...this.getLabelDesign(plannedEvent.attributes.request_status),
                    position: "absolute",
                    textTransform: "capitalize",
                    right:this.state.languagesLogin === 'rtl' ? '':'40px',
                    left:this.state.languagesLogin === 'rtl' ? '40px':'',

                  }}
                >
                  {this.getCurrentStatus(plannedEvent.attributes.request_status)}
                </span>
              }

              <Box className="planner-content">
                <Box style={{
                  display: 'flex',
                  gap: '10px'
                }}>
                  {this.profileImage(plannedEvent.attributes.image, plannedEvent.attributes.name)}
                  <Box
                    className="personalDetailsForPending"

                  >
                    <Typography
                      className="requesterName"
                      style={FormApprovalWorkflowStyles.RequesterName}
                    >
                      {plannedEvent.attributes.name}
                    </Typography>
                    <Typography
                      className="subHeaderForPending"
                      style={FormApprovalWorkflowStyles.subHeader}
                    >
                      {plannedEvent.attributes.firm}
                    </Typography>
                    <Box
                      className="locationForPending"
                      style={{ display: "flex", paddingTop: "5px" }}
                    >
                      <Typography
                        className="pendingLocationLabel"
                        style={FormApprovalWorkflowStyles.subHeader}
                      >
                        {this.getStringTransactions('Location')}{" "}
                      </Typography>
                      &nbsp;
                      <Typography
                        className="pendingLocationLabelAnswer"
                        style={FormApprovalWorkflowStyles.subHeaderAnswer}
                      >
                        {plannedEvent.attributes.location}
                      </Typography>
                    </Box>
                    <Box className="ForPending" style={{ display: "flex", flexWrap: "wrap" }}>
                      <Typography
                        className="pendingadviceLabel"
                        style={FormApprovalWorkflowStyles.subHeader}
                      >{this.getStringTransactions('JurisdictionAdvice')}
                      </Typography>
                      &nbsp;
                      <Typography
                        className="pendingadviceLabelanswer"
                        style={FormApprovalWorkflowStyles.subHeaderAnswer}
                      >
                        {plannedEvent.attributes.jurisdiction_advice}
                      </Typography>
                    </Box>
                    <Box className="QualificationsForPending" style={{ display: "flex", flexWrap: "wrap" }}>
                      <Typography
                        className="pendingqualificationLabel"
                        style={FormApprovalWorkflowStyles.subHeader}
                      >
                        {this.getStringTransactions('Qualifications')}
                      </Typography>
                      &nbsp;
                      <Typography
                        className="pendingqualificationLabelAnswer"
                        style={FormApprovalWorkflowStyles.subHeaderAnswer}
                      >
                        {plannedEvent.attributes.qualification}
                      </Typography>
                    </Box>
                  </Box>

                </Box>

                <Box>
                  <Box
                    style={FormApprovalWorkflowStyles.titleBox}
                  >
                    <Typography style={FormApprovalWorkflowStyles.subHeader2}>
                      {this.getStringTransactions('Experience')}
                    </Typography>
                    &nbsp;
                    <Typography
                      style={{ ...FormApprovalWorkflowStyles.subHeaderAnswer, marginTop: "28px" }}
                      id="requestedExpYear"
                    >
                      {plannedEvent.attributes.experience}  {this.getExperienceLabel(plannedEvent.attributes.experience)}
                    </Typography>
                  </Box>
                  <Box
                    id="requestedrating"
                    style={FormApprovalWorkflowStyles.titleBox}
                  >
                    {this.average_rating_display(plannedEvent)}

                  </Box>
                </Box>
              </Box>
              {
                plannedEvent.attributes.request_status !== "pending" &&
                <Box style={{ marginTop: '20px',marginBottom: '20px' }} id="ongoing-btns">
                  <Box style={{ display: "flex", flexWrap: 'wrap', gap: '15px' }}>
                    <button style={{ ...FormApprovalWorkflowStyles.chatBtn, backgroundColor: 'transparent',display:'flex', alignItems:'center', gap:'5px', opacity: this.getValue(plannedEvent), cursor: this.checkRejectedStatus(plannedEvent.attributes.request_status) ? "not-allowed" : "pointer" }}
                      disabled={this.checkRejectedStatus(plannedEvent.attributes.request_status)}
                      onClick={() => this.gotoChat(plannedEvent.attributes.planner_id, plannedEvent.attributes.name, plannedEvent.attributes.image)} data-test-id="chatBtn" id="ongoing-btns-chat">
                        <div>
                          {this.getStringTransactions('Chat')}
                        </div>
                        {Number(plannedEvent?.unreadCount) > 0 && <div style={{ backgroundColor: '#e25153', borderRadius: '50%', width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {plannedEvent?.unreadCount.toString()}
                        </div>}
                      </button>
                      
                    {this.renderScheduleButton(plannedEvent)}
                  </Box>
                  {this.renderModifyBookingBtn(plannedEvent)}


                </Box>
              }
              <Box>
                <Typography id="requestedExpFocus" style={FormApprovalWorkflowStyles.MainTitle3}>
                  {this.getStringTransactions('Focus')}:
                </Typography>
                <Box
                  id="requestedExpFocusWrapper"
                  style={{ display: "flex", justifyContent: "space-between", marginTop: "8px", flexWrap: "wrap" }}
                >
                  <Box id="requestedExpFocusContent">
                    {plannedEvent.attributes.focus.map((item) => {
                      return (
                        <ButtonFocusStyle style={FormApprovalWorkflowStyles.MainTitle4} key={item.name}>
                          {item.name}
                        </ButtonFocusStyle>
                      );
                    })}
                  </Box>
                  <Box id="requestedExpFocusMore">
                    <span
                      style={FormApprovalWorkflowStyles.btnLabel2}
                      onClick={(event) => {
                        this.showMore(
                          this.getShow(plannedEvent.id), event
                        );
                      }}
                      data-test-id="showMore"
                    >
                      {this.showMoreData(plannedEvent.id)}
                    </span>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box style={{ display: this.showNoneData(plannedEvent.id) }}>
              <Box style={FormApprovalWorkflowStyles.cardExpand}>
                <div className="table-container">
                  <table className={`responsive-table ${this.state.languagesLogin === 'rtl'? 'rtlText':''   }`}>
                    <tbody>
                      <tr>
                        <th>{this.getStringTransactions('Email')}:</th>
                        <td style={FormApprovalWorkflowStyles.tdAnswers}>
                          {this.showNameData(plannedEvent)}
                        </td>
                      </tr>
                      <tr>
                        <th>{this.getStringTransactions('Certification')}:</th>
                        <td>{plannedEvent.attributes.certificate}</td>
                      </tr>
                      <tr>
                        <th>{this.getStringTransactions('Quali')}:</th>
                        <td>{plannedEvent.attributes.qualification}</td>
                      </tr>
                      <tr>
                        <th>{this.getStringTransactions('Firm')}:</th>
                        <td>
                          <div style={{ display: "flex", justifyContent: "flex-start" }}>
                            <span>{plannedEvent.attributes.url} &nbsp;</span>
                            <span>
                              <div onClick={() => this.ensureHttps(plannedEvent.attributes.url)} style={{
                                cursor: "pointer"
                              }} data-test-id="open-url"  >
                                <img src={openIcon} alt="" style={{ width: '17px', borderRadius: '0px', marginTop: "3px" }} />
                              </div>
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>{this.getStringTransactions('EmpHist')}:</th>
                        <td>{plannedEvent.attributes.employment_history}</td>
                      </tr>
                      <tr>
                        <th>{this.getStringTransactions('DisclosureTitle')}:</th>
                        <td>{plannedEvent.attributes.disclosure}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <hr />
                <Box style={FormApprovalWorkflowStyles.personalDetails}>
                  <Box style={{ display: "flex", alignItems: "center", gap: "7px" }}>
                    <Typography
                      style={{ color: "#fff", fontSize: "14px", fontWeight: 500 }}
                    >
                      {this.getStringTransactions('RatRew')}:
                    </Typography>
                    {this.isUpIcon(plannedEvent)}
                  </Box>
                  <Box id="requestedrating">
                    <Box style={{ display: "flex", alignItems: "center", gap: "7px" }} >
                      {this.average_rating_display(plannedEvent)}
                    </Box>
                    <Box>
                      <Typography style={FormApprovalWorkflowStyles.totalReviewTxt}>
                        {this.getReviewMessage(plannedEvent)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box style={{ display: this.isUpData() }}>
                    {plannedEvent.attributes.rating_and_reviews.slice(0, 2).map((ratingData, index: number) => {
                      return (
                        <>
                          <Box className="details" key={index} style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "20px"
                          }} id="details">
                            <Box className="profile-name" style={FormApprovalWorkflowStyles.profileName} id="profileName">

                              {this.renderSmallProfile(ratingData.user_photo, ratingData.user_name)}

                              <Typography style={FormApprovalWorkflowStyles.reviewerName} className="reviewerName">{ratingData.user_name}</Typography>
                            </Box>
                            <Box id="dateRating" style={FormApprovalWorkflowStyles.profileName} className="date-rating">
                              <Typography style={{
                                ...FormApprovalWorkflowStyles.reviewerName,
                                opacity: "0.5",
                                fontWeight: 500,
                              }} className="date-of-review" >{ratingData.date}</Typography>
                              <Box id="reviewStarFordetailsReviews" style={FormApprovalWorkflowStyles.fontSizeText}>
                                <Rating value={ratingData.rating} name="size-small" emptyIcon={<StarIcon fontSize="inherit" style={FormApprovalWorkflowStyles.stareColor} />} readOnly precision={0.5} size="small" />
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography style={FormApprovalWorkflowStyles.consecteturText} className="consecteturText" data-test-id="main-review-content">{ratingData.description}</Typography>
                          </Box>
                        </>
                      )
                    })}
                    {
                      plannedEvent.attributes.rating_and_reviews.length > 2 &&
                      <Box className="all-review-btn" data-test-id="dialogReviewModal" onClick={() => { this.getAllReviewsList(plannedEvent) }} style={{ ...FormApprovalWorkflowStyles.marginTopBox, marginBottom: "12px" }}>
                        <span style={FormApprovalWorkflowStyles.allReviewBtn}>{this.getStringTransactions('AllRev')}</span>
                      </Box>
                    }
                  </Box>
                </Box>
              </Box>

            </Box>

          </Box>
        </Grid>
        <Grid item md={1} xs={1}>
          {
            plannedEvent.attributes.service_completed_status === "requested" &&
            <Box
              data-test-id="delete-popup"
              onClick={(event: React.MouseEvent<HTMLElement>) => this.handleDeletePopupClick(event, plannedEvent)}
              style={FormApprovalWorkflowStyles.threeDotSVG}
              className="acceptedSVG">
                <img src={threeDots} style={FormApprovalWorkflowStyles.threeDots} />
              {plannedEvent.deletePopup &&
                <Menu
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                  }}
                  transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                  }}
                  PaperProps={{
                    style: { padding: "0 15px", borderRadius: '12px' }
                  }}
                  id="simple-menu"
                  open={true}
                  data-test-id="close-delete-btn"
                  onClose={() => this.onDelete()}
                  style={FormApprovalWorkflowStyles.deleteTxt}
                >
                  <MenuItem onClick={this.deleteWealthPlannerService} data-test-id="delete-btn" >
                    {this.getStringTransactions('Delete')}
                  </MenuItem>
                </Menu>}
            </Box>
          }
        </Grid>
        {
          plannedEvent.attributes.new_services_added &&
          <Grid item md={12} style={{ ...FormApprovalWorkflowStyles.viewServiceBox, display: this.showNoneData(plannedEvent.id) }}>
            <span style={FormApprovalWorkflowStyles.viewServices} data-test-id="view-service-2" onClick={() => {
              this.goToServices(plannedEvent.attributes.planner_id)
            }} >
              {this.getStringTransactions('ViewServices')}
            </span>
          </Grid>
        }
        {
          this.checkReviewEligibility(plannedEvent.attributes.service_completed_status, plannedEvent.attributes.review_status)
          &&
          <Grid item md={12} style={FormApprovalWorkflowStyles.writeAreview} >
            <span style={FormApprovalWorkflowStyles.writeAReviewBtn} data-test-id="write-review" onClick={() => this.handleWriteAReview({
              currentPlannerId: plannedEvent.attributes.planner_id,
              currentPlannerImage: plannedEvent.attributes.image,
              currentPlannerName: plannedEvent.attributes.name
            })}>{this.getStringTransactions('WriteReview')}</span>
          </Grid>
        }

      </Grid >
    )
  }


  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="pagedashboard-wrapper" dir={this.state.languagesLogin}>
          <div>
            <Typography style={FormApprovalWorkflowStyles.MainTitle}>{this.getStringTransactions('EstatePlanning')}</Typography>
            <Box style={FormApprovalWorkflowStyles.mainBtnWrapper}>
              <Box style={FormApprovalWorkflowStyles.newServiceBtn}>
                <img style={FormApprovalWorkflowStyles.BtnImg} src={PlusIcon} alt="" />
                <Typography style={FormApprovalWorkflowStyles.btnLabel} data-test-id="start-service" onClick={() => {
                  this.startNewService()
                }}> {this.getStringTransactions('StartNewService')}</Typography>
              </Box>
            </Box>
            <Box style={FormApprovalWorkflowStyles.marginTopBox}>
              <Typography style={FormApprovalWorkflowStyles.MainTitle}>{this.getStringTransactions('Requested')}</Typography>
              {
                this.state.plannedEvents.filter((item) => item.attributes.service_completed_status === "requested").map((plannedEvent) => {
                  return (
                    <>
                      {this.renderPlannedEvent(plannedEvent)}
                    </>
                  )
                })
              }
              {this.state.plannedEvents.filter((item) => item.attributes.service_completed_status === "requested").length === 0 &&
                <Box data-test-id="no-requested-service">
                  <Typography style={FormApprovalWorkflowStyles.emptyServiceLable}>{this.getStringTransactions('NoNewRequestedService')} </Typography>
                </Box>
              }
            </Box>
            <Box style={FormApprovalWorkflowStyles.marginTopBox}>
              <Typography style={FormApprovalWorkflowStyles.MainTitle} id="Ongoing">{this.getStringTransactions('Ongoing')}</Typography>
              {
                this.state.plannedEvents.filter((item) => item.attributes.service_completed_status === "ongoing").map((plannedEvent) => {
                  return (
                    <>
                      {this.renderPlannedEvent(plannedEvent)}
                    </>
                  )
                })
              }
              {this.state.plannedEvents.filter((item) => item.attributes.service_completed_status === "ongoing").length === 0 &&
                <Box>
                  <Typography style={FormApprovalWorkflowStyles.emptyServiceLable}>{this.getStringTransactions('NoOngoingService')}</Typography>
                </Box>
              }
            </Box>
            <Box style={FormApprovalWorkflowStyles.marginTopBox}>
              <Typography style={FormApprovalWorkflowStyles.MainTitle}>{this.getStringTransactions('Completed')}</Typography>
              {
                this.state.plannedEvents.filter((item) => item.attributes.service_completed_status === "completed").map((plannedEvent) => {
                  return (
                    <>
                      {this.renderPlannedEvent(plannedEvent)}
                    </>
                  )
                })
              }
              {this.state.plannedEvents.filter((item) => item.attributes.service_completed_status === "completed").length === 0 &&
                <Box>
                  <Typography style={FormApprovalWorkflowStyles.emptyServiceLable}>{this.getStringTransactions('NoCompletedService')}</Typography>
                </Box>
              }

            </Box>

          </div>
          <Dialog
            open={this.state.isAllReviewopupOpen}
            keepMounted
            data-test-id="dialogModel"
            onClose={() => this.handleReviewDialogClose()}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ style: this.dialogCashPaperStyle }}
          >
            <DialogContent style={FormApprovalWorkflowStyles.dialogContent}>
              <Box>
                <Box style={FormApprovalWorkflowStyles.profileNameNew}>
                  <Box style={FormApprovalWorkflowStyles.average_rating_fontSize}>{this.state.selectedPlannedEvent?.attributes.average_rating}</Box>&nbsp;&nbsp;
                  <Box style={FormApprovalWorkflowStyles.fontSizeText} className="">
                    {this.state.selectedPlannedEvent?.attributes.average_rating && (
                      <Rating
                        name="size-medium"
                        size="small"
                        value={this.state.selectedPlannedEvent.attributes.average_rating}
                        emptyIcon={<StarIcon fontSize="inherit" style={FormApprovalWorkflowStyles.stareColor} />}
                        readOnly
                        precision={0.5}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box style={FormApprovalWorkflowStyles.textlabel_NameOfCorporation}>
                {this.getStringTransactions('basedOut')} {this.state.selectedPlannedEvent?.attributes?.total_reviews} {this.getStringTransactions('reviews')}
              </Box>
              <Box style={FormApprovalWorkflowStyles.marginTopBox}>
                {this.state.allReviews.map((ratingDataModel, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Box className="details" style={FormApprovalWorkflowStyles.displayBox} id="details" >
                        <Box className="profile-name" style={FormApprovalWorkflowStyles.profileName} id="profileName" data-test-id="profile-name-box">
                          {this.profileImage(ratingDataModel.attributes.user_photo, ratingDataModel.attributes.user_name)}
                          <Typography style={FormApprovalWorkflowStyles.reviewerName} className="reviewerName">
                            {ratingDataModel.attributes.user_name}
                          </Typography>
                        </Box>
                        <Box id="dateRating" className="date-rating" style={FormApprovalWorkflowStyles.profileName}>
                          <Typography className="date-of-review" style={FormApprovalWorkflowStyles.reviewerNameText}>
                            {ratingDataModel.attributes.date}
                          </Typography>
                          <Box id="reviewStarFordetailsReviews" style={FormApprovalWorkflowStyles.fontSizeText}>
                            <Rating
                              name="size-small"
                              emptyIcon={<StarIcon fontSize="inherit" style={FormApprovalWorkflowStyles.stareColor} />}
                              value={ratingDataModel.attributes.rating}
                              readOnly
                              precision={0.5}
                              size="small"
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Typography data-test-id="main-review-content" style={FormApprovalWorkflowStyles.consecteturText}>
                          {ratingDataModel.attributes.description}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  );
                })}
              </Box>
            </DialogContent>
          </Dialog>
          <Dialog
            open={this.state.openDeleteModel}
            keepMounted
            data-test-id="dialogModel"
            onClose={this.onDelete}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{ style: this.dialogPaperStyle }}
          >
            <DialogTitle id="alert-dialog-slide-title" style={FormApprovalWorkflowStyles.dialogTitle}>
              <span style={FormApprovalWorkflowStyles.dialogTitleHeader}>{this.getStringTransactions('DeletePopupMsg')}</span></DialogTitle>
            <DialogContent style={FormApprovalWorkflowStyles.dialogContent}>
              <DialogContentText id="alert-dialog-slide-description"
                style={FormApprovalWorkflowStyles.dialogContainer}>
                <div className="modal-button-container">

                  <div className="modal-button modal-discard" data-test-id='popup-discard-click' onClick={() => this.onDelete()}>
                    {this.getStringTransactions('Cancel')}
                  </div>
                  <div className="modal-button modal-save" style={{
                    color: "#c9f9f6",
                    border: "1px solid #c9f9f6",
                  }} data-test-id='popup-save-click' onClick={() => this.dialogModel()}>
                    {this.getStringTransactions('Yes')}
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Box>
            <Dialog
              open={this.state.deleteSuccessPopup}
              keepMounted
              data-test-id="dialogModel"
              onClose={this.onDeletePopup}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              PaperProps={{
                style: {
                  ...this.dialogPaperStyle,
                  padding: '0px'
                }
              }}
            >
              <DialogTitle id="alert-dialog-slide-title" style={{ ...FormApprovalWorkflowStyles.dialogTitle, textAlign: "center", marginTop: "2rem" }}><img src={modelSuccess} /></DialogTitle>
              <DialogContent style={{ ...FormApprovalWorkflowStyles.dialogContainer, margin: "1rem 3rem" }}>
                <DialogContentText id="alert-dialog-slide-description">
                  <span style={FormApprovalWorkflowStyles.successMsg}>
                    {this.getStringTransactions('PlannerDeleteSuccess')}

                  </span>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </Box>
        </div>
      </ThemeProvider>
    );
  }
}
type IStyle = { [key: string]: React.CSSProperties }


const FormApprovalWorkflowStyles: IStyle = {
  allReviewBtn: { borderRadius: '6px', border: '1px solid #C9F9F6', padding: '5px 20px', color: "#C9F9F6", cursor: 'pointer' },
  reviewerName: { color: '#fff', fontWeight: 400 },
  profileName: { display: 'flex', alignItems: 'center', gap: '10px' },
  reviewerDP: { borderRadius: '50px', width: '50px', height: '50px' },
  tdQuestions: { color: '#fff', fontSize: '14px', fontWeight: 400 },
  tdQuestions1: { color: '#fff', fontSize: '14px', fontWeight: 400, paddingLeft: '15px' },
  tdAnswers: { color: '#2CE2D5', fontSize: '14px', fontWeight: 600, paddingLeft: '15px' },
  personalDetails: { padding: '10px 10px 0px 10px' },
  cardExpand: { background: '#395D6B', borderRadius: "8px" },
  timeMsg: { color: '#fff', marginBottom: '22px', fontSize: '12px', fontWeight: 400, fontFamily: "Poppins" },
  ScheduleCallBtn: { color: '#3C3C51', border: '1px solid #C9F9F6', background: '#C9F9F6', padding: '9px 48px', fontWeight: 600, borderRadius: '5px', cursor: 'pointer' },
  chatBtn: { color: '#C9F9F6', border: '1px solid #C9F9F6', padding: '9px 70px', fontWeight: 600, borderRadius: '5px', cursor: 'pointer' },
  threeDotSVG: { color: 'rgb(45, 226, 213)', fontSize: '20px', cursor: 'pointer', position: 'relative', marginTop: "12px", width:'fit-content' },
  BtnImg: { width: '85px' },
  labelDesignForRejected: { right: '40px', top: '-21px', background: '#f4beba', color: '#d9545f', fontWeight: 600, padding: '5px 20px', borderRadius: '7px' },
  labelDesignForPending: { right: '40px', top: '-21px', background: '#EEE6BE', color: '#FFB000', fontWeight: 600, padding: '5px 20px', borderRadius: '7px' },
  labelDesignForAccepted: { right: '40px', top: '-21px', background: '#0ABE64', color: '#055D52', fontWeight: 600, padding: '5px 20px', borderRadius: '7px' },
  MainTitle: { color: '#fff', fontWeight: 700, fontSize: '18px', marginBottom: "22px" },
  mainBtnWrapperForRequested: { padding: '20px', width: '100%', background: '#3C3C51', border: '1px solid #57575D', boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.15)', borderRadius: '12px', position: "relative" },
  MainTitle3: { color: '#fff', fontWeight: 600, padding: '20px 0px', paddingBottom: '7px' },
  MainTitle4: { margin: '10px 20px 10px 0px', color: '#fff', fontWeight: 400, background: '#395D6B', borderRadius: '15px', padding: '8px 20px', fontSize: '16px', fontFamily: 'Poppins', fontStyle: 'normal', textTransform: 'capitalize' },
  newServiceBtn: { background: '#395D6B', borderRadius: '10px', display: 'flex', alignItems: 'center', cursor: 'pointer' },
  mainBtnWrapper: { paddingTop: '18px', paddingBottom: "24px" },
  btnLabel: { color: '#fff', fontWeight: 600 },
  btnLabel2: { color: '#2DE2D5', fontWeight: 600, cursor: 'pointer' },
  leftContainer: { display: 'flex' },
  RequesterName: { color: '#fff', fontWeight: 600, fontSize: '18px' },
  subHeader: { color: '#928F98', fontWeight: 400, fontSize: '16px', fontFamily: "Poppins", },
  subHeader2: { color: '#928F98', fontWeight: 400, fontSize: '12px', marginTop: "28px" },
  subHeaderAnswer: { color: '#2DE2D5', fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 500 },
  webStyleMainBox: { borderRadius: "12px", width: "98%", border: "1px solid #57575D", background: "#395d6b", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.15)", marginBottom: '30px' },
  expandMoreRounded: { color: '#3cd7c6' },
  handleClickMargin: { margin: '-5px 15px', cursor: 'pointer' },
  average_rating_fontSize: { color: '#fff', fontSize: '12px' },
  fontSizeText: { fontSize: '12px', color: 'white' },
  stareColor: { color: '#ceccc2' },
  profileNameNew: { display: 'flex', alignItems: 'center' },
  textlabel_NameOfCorporation: { color: "#928F98", fontFamily: "Poppins", fontSize: "16px", fontStyle: "normal", fontWeight: 400, display: "flex" },
  marginTopBox: { marginTop: '20px' },
  displayBox: { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '40px' },
  reviewerNameText: { color: '#fff', opacity: '0.5', fontWeight: 500 },
  emptyServiceLable: { color: '#C4C4CA', fontWeight: 500, padding: '20px 0px', textAlign: "center", fontSize: "1rem" },
  viewServices: { padding: "10px 6rem", fontWeight: 700, color: "#3C3C50", borderRadius: "5px", background: "#C9F9F6", textAlign: "center", cursor: 'pointer' },
  writeAReviewBtn: { color: '#C9F9F6', border: '1px solid #C9F9F6', padding: "10px 6rem", fontWeight: 700, borderRadius: "5px", textAlign: "center", cursor: 'pointer' },

  titleBox: { display: "flex", justifyContent: "center", alignItems: "center" },
  viewServiceBox: { marginTop: "10px", display: "flex", justifyContent: "center", marginBottom: "10px", textAlign: "center" },
  dialogContainer: { color: 'rgb(255, 255, 255) !important', fontSize: '20px !important', fontFamily: 'Poppins !important', fontWeight: 500, margin: '2rem 0rem' },
  dialogContent: {
    padding: '0px!important',
    marginBottom: '0px',
    paddingTop: '15px',
    overflowY: 'auto', // Allow vertical scrolling
    overflowX: 'hidden', // Prevent horizontal scrolling
    maxHeight: '80vh', // Optional: restrict max height of the dialog content
  },
  consecteturText: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 400,
    margin: '20px 0px',
    wordWrap: 'break-word', // Ensure long words break to next line
    wordBreak: 'break-word', // Break long words to avoid overflow
    whiteSpace: 'pre-wrap',  // Preserve spaces and line breaks
  },
  dialogTitle: { padding: '0px 65px', marginTop: "4rem" },
  dialogTitleHeader: { color: '#fff', textAlign: 'center', fontSize: '13px', fontFamily: 'Poppins', fontWeight: 600 },
  writeAreview: { textAlign: 'center', marginBottom: "16px", marginTop: "2rem" },
  deleteTxt: { color: 'black', right: 0, padding: '0', fontSize: '14px', borderRadius: '10px' },
  totalReviewTxt: { color: "#fff", fontSize: "14px", fontWeight: 500, opacity: 0.5, marginBottom: "12px" },
  servicePopUpMsg: { background: '#C9E4AD', width: '98%', display: 'flex', justifyContent: 'space-between', padding: '5px 10px', borderRadius: '5px', marginBottom: '18px' },
  serviceAddedMsg: { color: '#0ABE64', fontWeight: 500, paddingTop: '10px', paddingBottom: '10px' },
  viewServiceBtn: { cursor: 'pointer', color: '#2F92FC', fontWeight: 700, textDecorationLine: 'underline', paddingTop: '10px', paddingBottom: '10px' },
  tableRow: { height: "2rem" },
  successMsg: { color: '#fff', fontSize: '20px !important', fontFamily: 'Poppins', fontWeight: 500 },
  noImg: {
    justifyContent: 'center',
    display: "flex",
    height: "94px",
    width: "94px",
    borderRadius: "50%",
    fontWeight: 600,
    color: "#fff",
    fontSize: "32px",
    alignItems: "center",
    backgroundColor: "2CE2D5",
    textTransform: "uppercase"
  },
};
const ButtonFocusStyle = styled(Button)({
  background: "#395D6B",
  color: "#FFF",
  padding: "8px 20px",
  fontSize: "16px",
  borderRadius: "15px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  textAlign: "center",
  textTransform: "capitalize",
  margin: '10px 20px 10px 0px', '&:hover': {
    backgroundColor: "#395D6B",
  },
})
// Customizable Area End
